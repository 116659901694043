import { IOrganization } from '../entity/IOrganization';

const organizationKeyName = 'activeOrganization';

export const getActiveOrganization = () => {
  if (localStorage.getItem(organizationKeyName)) {
    return JSON.parse(localStorage.getItem(organizationKeyName)!) as IOrganization;
  }
};

export const getActiveOrganizationUuid = () => {
  return getActiveOrganization() ? getActiveOrganization()!.uuid : '';
};

export const setActiveOrganization = (organization: IOrganization) =>
  localStorage.setItem(organizationKeyName, JSON.stringify(organization));

// Token
export const setToken = (token: string) => localStorage.setItem('token', token);
export const getToken = () => localStorage.getItem('token');
export const removeToken = () => localStorage.removeItem('token');
