import { FC } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { IconButton, Box, Tooltip } from '@material-ui/core';
import projectLogo from '../../assets/images/inceptionlogo.png';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import { setSidebarToggleDesktop } from '../../reducers/ThemeOptions';
import { useTranslation } from 'react-i18next';

const HeaderLogo: FC<any> = ({ sidebarToggleDesktop, setSidebarToggleDesktop }) => {
  const { t } = useTranslation();
  const toggleSidebarDesktop = () => {
    setSidebarToggleDesktop(!sidebarToggleDesktop);
  };

  return (
    <div className={'app-header-logo'}>
      <Box className="header-logo-wrapper" title="Inception">
        <Link
          to={
            sessionStorage.getItem('module')
              ? `/${sessionStorage.getItem('module')}`
              : '/administration'
          }
          className="header-logo-wrapper-link">
          <IconButton color="primary" size="medium">
            <img className="app-header-logo-img" alt="Inception" src={projectLogo} />
          </IconButton>
        </Link>
        <Box className="header-logo-text">Inception</Box>
      </Box>
      <Box>
        <Tooltip title={t('ToggleSidebar')!} placement="right">
          <IconButton color={'default'} onClick={toggleSidebarDesktop} size="medium">
            {sidebarToggleDesktop ? <MenuRoundedIcon /> : <MenuOpenRoundedIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  sidebarToggleDesktop: state.ThemeOptions.sidebarToggleDesktop,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleDesktop: (enable: boolean) => dispatch(setSidebarToggleDesktop(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
