import axiosInstance from '../apis/arp';
import { getActiveOrganizationUuid } from '../utils/LocalStorageHelper';

export const fetchUserInfo = () => {
  return function (dispatch: any) {
    axiosInstance
      .get(`/user/info`, {
        params: {
          organizationUuid: getActiveOrganizationUuid(),
        },
      })
      .then((response) => {
        dispatch({
          type: 'FETCH_USER_INFO_SUCCESSFUL',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_USER_INFO_FAIL' });
      });
  };
};

export const fetchUserPrivileges = (organizationUuid: string) => {
  return function (dispatch: any) {
    return axiosInstance
      .get(`/my-privileges`, {
        params: {
          organizationUuid,
        },
      })
      .then((response) => {
        dispatch({
          type: 'FETCH_USER_PRIVILEGES',
          payload: response.data,
        });
      });
  };
};

export function fetchLoggedUserOrganizations() {
  return function (dispatch: any) {
    dispatch({
      type: 'FETCH_LOGGED_USER_ORGANIZATIONS',
    });

    axiosInstance
      .get(`/user/me/organizations`)
      .then((response) => {
        dispatch({
          type: 'FETCH_LOGGED_USER_ORGANIZATIONS_SUCCESSFUL',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_LOGGED_USER_ORGANIZATIONS_FAIL' });
      });
  };
}

export function fetchLoggedUserModules() {
  return function (dispatch: any) {
    dispatch({
      type: 'FETCH_LOGGED_USER_MODULES',
    });

    axiosInstance
      .get(`/organization/${getActiveOrganizationUuid()}/sub-systems`)
      .then((response) => {
        dispatch({
          type: 'FETCH_LOGGED_USER_MODULES_SUCCESSFUL',
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({ type: 'FETCH_LOGGED_USER_MODULES_FAIL' });
      });
  };
}
