import { FC } from 'react';
import loaderLogo from '../../assets/images/inceptionlogo.png';
import { LinearProgress } from '@material-ui/core';

interface Props {
  isLoading: boolean;
}
export const ComponentLoader: FC<Props> = ({ isLoading }) => (
  <div hidden={!isLoading} className="linear-component-loader ">
    <img className={'loader-logo'} alt="Pismonoša" src={loaderLogo} />
    <LinearProgress className="linear-progress" />
  </div>
);

export const SimpleLoader: FC<Props> = ({ isLoading }) => (
  <LinearProgress hidden={!isLoading} className="linear-progress" />
);
