import { FC } from 'react';
import { List, Typography } from '@material-ui/core';
import useRouter from '../../utils/useRouter';
import SidebarMenuListItem from './SidebarMenuListItem';
import { Privileges } from '../../entity/Privileges';

const SidebarMenuList: FC<SidebarMenuListProps> = ({ pages, depth, router }) => (
  <List className="p-0">
    {pages.reduce((items, page) => reduceChildRoutes({ items, page, depth, router }), [])}
  </List>
);

const reduceChildRoutes = ({ router, items, page, depth }: ChildRoutesProps) => {
  if (page.content) {
    const open = !!page.content.find((e: any) => e.to === router.location.pathname);
    page.content?.length > 0 &&
      items.push(
        <SidebarMenuListItem
          title={page.label}
          depth={depth}
          icon={page.icon}
          key={page.label}
          label={page.badge}
          isOpen={open}>
          <div className="sidebar-menu-children py-2">
            <SidebarMenuList depth={depth + 1} pages={page.content} router={router} />
          </div>
        </SidebarMenuListItem>
      );
  } else {
    items.push(
      <SidebarMenuListItem
        depth={depth}
        href={page.to}
        target={page.target}
        icon={page.icon}
        key={page.label}
        label={page.badge}
        title={page.label}
      />
    );
  }
  return items;
};

const SidebarMenu: FC<any> = ({
  title,
  pages,
  className,
  component: Component,
  documentTypes,
  ...rest
}) => {
  let userPages: IPage[] = [];

  pages.forEach((page: IPage) => {
    if (
      page.module ===
      (sessionStorage.getItem('module')
        ? sessionStorage.getItem('module')
        : 'administration')
    ) {
      userPages.push(page);
    }
  });

  const router = useRouter();
  return (
    <Component {...rest} className={className}>
      {title && <Typography className="app-sidebar-heading">{title}</Typography>}
      <SidebarMenuList depth={0} pages={userPages} router={router} />
    </Component>
  );
};

export default SidebarMenu;

interface IPage {
  content?: any;
  description?: string;
  label: string;
  to?: string;
  privilege?: Privileges;
  icon: any;
  badge?: any;
  module?: any;
  hidden?: boolean;
  target?: string;
}
interface SidebarMenuListProps {
  pages: IPage[];
  depth: number;
  router: any;
}
interface ChildRoutesProps {
  page: IPage;
  depth: number;
  router: any;
  items: any;
}
