import { removeToken } from '../utils/LocalStorageHelper';

export const logOut = () => {
  removeToken();
  window.location.href = '/login';
};

export const logOutWithNextUrl = () => {
  removeToken();
  const url = window.location.href;
  window.location.href = url.replace(window.location.origin, '');
};
