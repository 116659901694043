import { FC, useState } from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel } from '@material-ui/core';
import clsx from 'clsx';
import { ITableHeaderColumn } from '../../entity/ITable';

interface Props {
  headerCols: ITableHeaderColumn[];
  headerWrap?: boolean;
  sortTable?: (sort?: string) => void;
  secondHeaderRow?: ITableHeaderColumn[];
}
export const HeaderTable: FC<Props> = ({
  headerCols,
  headerWrap,
  sortTable,
  secondHeaderRow,
}) => {
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('number');

  const tableHeadKeys: string[] = headerCols.map((c) => c.id);
  const sortLabelClick = (tableHeadKey: string, sortOptions: any) => {
    setOrder(order === 'asc' ? 'desc' : 'asc');
    setOrderBy(tableHeadKey);
    sortTable!(order === 'asc' ? sortOptions.desc : sortOptions.asc);
  };

  return (
    <TableHead
      className={clsx('table-head', {
        'text-nowrap': !headerWrap,
      })}>
      <TableRow>
        {headerCols &&
          Object.values(headerCols).map((col, i) => (
            <TableCell
              key={i}
              align={col.align}
              width={col.width}
              hidden={col.hidden}
              className={clsx({
                [col.class?.name || '']: col.class?.active,
              })}>
              {!!col.sortable ? (
                <TableSortLabel
                  active={orderBy === tableHeadKeys[i]}
                  direction={order}
                  onClick={() => sortLabelClick(tableHeadKeys[i], col.sortOptions)}>
                  {col.label}
                </TableSortLabel>
              ) : (
                col.label
              )}
            </TableCell>
          ))}
      </TableRow>
      <TableRow>
        {!!secondHeaderRow &&
          Object.values(secondHeaderRow).map((col, i) => (
            <TableCell key={i} align={col.align} width={col.width} hidden={col.hidden}>
              {col.label}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};
