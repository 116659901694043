const initState = {
  glassTypes: [],
  totalRows: 0,
  glassType: null,
  editForm: false,
  submitFailMode: false,
  loading: false,
};

const GlassTypeReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_GLASS_TYPES':
      return { ...state, loading: true };
    case 'FETCH_GLASS_TYPES_SUCCESSFUL':
      return {
        ...state,
        glassTypes: action.payload,
        //totalRows: action.payload.pagination.totalRows,
        loading: false,
      };
    case 'FETCH_GLASS_TYPES_FAIL':
      return {
        ...state,
        glassTypes: [],
        //totalRows: 0,
        loading: false,
      };
    case 'CHANGE_MODE':
      return {
        ...state,
        editForm: action.payload,
        submitFailMode: false,
        glassType: null,
      };
    case 'FETCH_GLASS_TYPE_SUCCESSFUL':
      return {
        ...state,
        glassType: action.payload,
        editForm: true,
        submitFailMode: false,
      };
    case 'FETCH_GLASS_TYPE_FAIL':
      return { ...state, glassType: null, editForm: false, submitFailMode: false };
    case 'EDIT_SAVE_GLASS_TYPE':
      return { ...state, submitFailMode: true };
    case 'EDIT_SAVE_GLASS_TYPE_SUCCESSFUL':
      return { ...state, glassType: null, editForm: false, submitFailMode: false };
    case 'EDIT_SAVE_GLASS_TYPE_FAIL':
      return { ...state, submitFailMode: true };
    default:
      return state;
  }
};

export default GlassTypeReducer;
