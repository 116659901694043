import { FC, MouseEventHandler } from 'react';
import i18n from '../../i18n';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '../../assets/fontAwesome/regular/faPaperPlane';
import { faEllipsisVertical } from '../../assets/fontAwesome/solid/faEllipsisVertical';
import { faPrint } from '../../assets/fontAwesome/solid/faPrint';
import { faCheck } from '../../assets/fontAwesome/solid/faCheck';
import { faFilter } from '../../assets/fontAwesome/solid/faFilter';
import { faArrowDownWideShort } from '../../assets/fontAwesome/solid/faArrowDownWideShort';
import { faListUl } from '../../assets/fontAwesome/solid/faListUl';
import { faXmark } from '../../assets/fontAwesome/solid/faXmark';
import { faMagnifyingGlass } from '../../assets/fontAwesome/solid/faMagnifyingGlass';
import { faCaretDown } from '../../assets/fontAwesome/solid/faCaretDown';
import { faFileMagnifyingGlass } from '../../assets/fontAwesome/regular/faFileMagnifyingGlass';
import { faQrcode } from '../../assets/fontAwesome/regular/faQrcode';
import { faArrowLeft } from '../../assets/fontAwesome/solid/faArrowLeft';
import { faFloppyDisk } from '../../assets/fontAwesome/solid/faFloppyDisk';
import { faPaintBrushFine } from '../../assets/fontAwesome/solid/faPaintBrushFine';
import { faTrashCan } from '../../assets/fontAwesome/regular/faTrashCan';
import { faDownload } from '../../assets/fontAwesome/light/faDownload';
import { faRotateRight } from '../../assets/fontAwesome/solid/faRotateRight';
import { faPlus } from '../../assets/fontAwesome/light/faPlus';
import { faAngleRight } from '../../assets/fontAwesome/solid/faAngleRight';
import { faPen } from '../../assets/fontAwesome/light/faPen';

const Icons = {
  Back: faArrowLeft,
  Search: faMagnifyingGlass,
  Save: faFloppyDisk,
  Confirm: faCheck,
  Send: faPaperPlane,
  Cancel: faXmark,
  Create: faPaintBrushFine,
  Print: faPrint,
  Actions: faEllipsisVertical,
  Filter: faFilter,
  Sort: faArrowDownWideShort,
  Columns: faListUl,
  Delete: faTrashCan,
  Choose: faFileMagnifyingGlass,
  ScanQR: faQrcode,
  Download: faDownload,
  Refresh: faRotateRight,
  Add: faPlus,
  Next: faAngleRight,
  Edit: faPen,
};

export type IButtonType =
  | 'Back'
  | 'Search'
  | 'Save'
  | 'Confirm'
  | 'Send'
  | 'Cancel'
  | 'Create'
  | 'Print'
  | 'Actions'
  | 'Filter'
  | 'Sort'
  | 'Columns'
  | 'Delete'
  | 'Choose'
  | 'ScanQR'
  | 'Download'
  | 'Refresh'
  | 'Add'
  | 'Next'
  | 'Edit';

interface Props {
  id?: string;
  type?: IButtonType;
  title?: string;
  icon?: any;
  size?: 'xs' | 's' | 'm' | 'l';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  className?: string;
  solid?: boolean;
  primary?: boolean;
  outlined?: boolean;
  active?: boolean;
  menu?: boolean;
  withoutText?: boolean;
  isResponsive?: boolean;
}

export const CButton: FC<Props> = ({
  id,
  type,
  title,
  icon,
  size = 'm',
  onClick,
  disabled,
  className,
  solid,
  primary,
  outlined,
  active,
  menu,
  withoutText,
  isResponsive,
}) => (
  <button
    id={id}
    disabled={disabled}
    className={clsx(`button-base button-${size}`, {
      solid,
      primary,
      active,
      outlined,
      responsive: isResponsive,
      [className || '']: !!className,
    })}
    onClick={onClick}
    type={onClick ? 'button' : 'submit'}>
    {(!!icon || !!type) && <FontAwesomeIcon icon={icon || Icons[type!]} />}
    {!withoutText && <div className="button-title">{title || i18n.t(type!) || ''}</div>}
    {menu && <FontAwesomeIcon icon={faCaretDown} />}
  </button>
);
