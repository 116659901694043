import { FC, useState, forwardRef, LegacyRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { ListItem, Button, Collapse } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const CustomRouterLink = forwardRef(
  (props, ref: LegacyRef<HTMLDivElement> | undefined) => {
    return (
      <div ref={ref} style={{ flexGrow: 1 }}>
        {/* @ts-ignore*/}
        <RouterLink {...props} />
      </div>
    );
  }
);
interface SidebarMenuListItemProps {
  title: string;
  href?: string;
  target?: string;
  depth: number;
  icon: any;
  label: string | number;
  isOpen?: boolean;
  className?: string;
}
const SidebarMenuListItem: FC<SidebarMenuListItemProps> = ({
  title,
  href,
  target,
  depth = 0,
  icon: Icon,
  label,
  isOpen = false,
  className,
  children,
}) => {
  const [open, setOpen] = useState(isOpen);

  const handleToggle = () => {
    setOpen((open) => !open);
  };

  let paddingLeft = 12;

  if (depth > 0) {
    paddingLeft = 12 + 15 * depth;
  }
  const style = {
    paddingLeft,
  };

  if (children) {
    return (
      <ListItem
        className={clsx(`app-sidebar-item depth-${depth}`, className)}
        disableGutters>
        <Button
          color="primary"
          className={clsx('app-sidebar-button', { active: open })}
          onClick={handleToggle}
          // target={target ? target : '_self'}
          style={style}>
          {Icon && <Icon className="app-sidebar-icon" />}
          <span>{title}</span>
          {/* {label && <span className="ml-auto badge ml-3 badge-warning">{label}</span>} */}
          {label > 0 && <span className="menu-item-label font-weight-bold">{label}</span>}
          {open ? (
            <ExpandLessIcon className="sidebar-expand-icon" color="inherit" />
          ) : (
            <ExpandLessIcon
              className="sidebar-expand-icon sidebar-expand-icon-rotate"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  } else {
    return (
      <ListItem
        className={clsx(`app-sidebar-item depth-${depth}`, className)}
        disableGutters>
        <Button
          // @ts-ignore
          activeClassName="active-item"
          color="primary"
          disableRipple
          variant="text"
          target={target ? target : '_self'}
          className={clsx('app-sidebar-button-wrapper', `depth-${depth}`)}
          component={CustomRouterLink}
          exact
          style={style}
          to={href}>
          {Icon && <Icon className="app-sidebar-icon" />}
          {title}
          {label > 0 && <span className="menu-item-label font-weight-bold">{label}</span>}
        </Button>
      </ListItem>
    );
  }
};

export default SidebarMenuListItem;
