const initState = {
  assetList: null,
  editForm: false,
  submitFailMode: false,
  loading: false,
  loadingItems: false,
  realQuantityUpdated: false,
  assetListItemsGrouped: [],
};

const AssetListsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_ASSET_LIST_SUCCESSFUL':
      return {
        ...state,
        assetList: action.payload,
        editForm: true,
        submitFailMode: false,
      };
    case 'FETCH_ASSET_LIST_FAIL':
      return { ...state, assetList: null, editForm: false, submitFailMode: false };
    case 'FETCH_ASSET_LIST_ITEMS_GROUPED':
      return { ...state, loading: true };
    case 'FETCH_ASSET_LIST_ITEMS_GROUPED_SUCCESSFUL':
      return { ...state, assetListItemsGrouped: action.payload, loading: false };
    case 'FETCH_ASSET_LIST_ITEMS_GROUPED_FAIL':
      return { ...state, assetListItemsGrouped: [], loading: false };
    default:
      return state;
  }
};

export default AssetListsReducer;
