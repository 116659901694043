import axiosInstance from '../../apis/arp';
import { IPagination } from '../../entity/IPagination';
import {
  IWmsDocument,
  IWmsDocumentNew,
  IWmsDocumentSearch,
  IWmsDocumentType,
} from '../../entity/wms/IWmsDocument';
import {
  IWmsDocumentItemNew,
  IWmsDocumentItemsSearch,
} from '../../entity/wms/IWmsDocumentItem';
import { getActiveOrganizationUuid } from '../../utils/LocalStorageHelper';

export const getWmsDocuments = (
  // organizationUuid: string,
  pagination: IPagination,
  documentTypeUuid: string,
  search?: IWmsDocumentSearch,
  fetchOptions?: string[]
) => {
  return axiosInstance
    .get(`/wms/documents`, {
      params: {
        organizationUuid: getActiveOrganizationUuid(),
        ...pagination,
        ...search,
        documentTypeUuid,
        fetchOptions,
      },
    })
    .then((response) => {
      return {
        documents: response.data.documents,
        totalRows: response.data.pagination.totalRows,
      };
    });
};

export const getWmsDocumentByUuid = (documentUuid?: string) => {
  return axiosInstance
    .get(`/wms/document/${documentUuid}`)
    .then((response) => response.data as IWmsDocument);
};

export const getWmsDocumentTypes = (
  // organizationUuid: string,
  nameLike?: string,
  smallInventory?: boolean
) => {
  return axiosInstance
    .get(`/${smallInventory ? 'si' : 'wms'}/document/types`, {
      params: {
        organizationUuid: getActiveOrganizationUuid(),
        nameLike,
      },
    })
    .then((response) => response.data.types);
};

export const getWmsDocumentTypeByUuid = (
  // organizationUuid: string,
  uuid: string
) => {
  return axiosInstance
    .get(`/wms/document/type/${uuid}`)
    .then((response) => response.data as IWmsDocumentType);
};

export const saveWmsDocument = (data: IWmsDocumentNew) => {
  return axiosInstance
    .post('/wms/document', {
      ...data,
      organization: { uuid: getActiveOrganizationUuid() },
    })
    .then((response) => response.data?.identifier as string);
};

export const copyItemsFromWHDocument = (
  sourceDocument: string,
  targetDocument: string
) => {
  return axiosInstance.patch(`/wms/document/${targetDocument}`, {
    copyItemsFromDocument: { uuid: sourceDocument },
    organization: { uuid: getActiveOrganizationUuid() },
  });
};

export const getWmsDocumentItems = (
  pagination: IPagination,
  search?: IWmsDocumentItemsSearch,
  fetchOptions?: (
    | 'PRODUCT'
    | 'DOCUMENT'
    | 'CREATED_BY'
    | 'CONTRACT_ITEM'
    | 'FINANCIAL_DATA'
    | 'SUM'
    | 'DOCUMENT_WAREHOUSE'
    | 'HIDE_EMPTY'
    | 'EMPLOYEE'
  )[],
  sort?: 'HRONOLOGICALLY_BY_DATE'
) => {
  return axiosInstance
    .get(`/wms/document/items`, {
      params: {
        organizationUuid: getActiveOrganizationUuid(),
        ...pagination,
        ...search,
        fetchOptions,
        sort,
      },
    })
    .then((response) => {
      return {
        items: response.data.items,
        totalRows: response.data.pagination.totalRows,
        sum: response.data.sum,
      };
    });
};

export const getWmsDocumentItemByUuid = (itemUuid?: string) => {
  return axiosInstance
    .get(`/wms/document/item/${itemUuid}`)
    .then((response) => response.data as IWmsDocument);
};

export const saveWmsDocumentItem = (data: IWmsDocumentItemNew) => {
  return axiosInstance
    .post('/wms/document/item', {
      ...data,
      organization: { uuid: getActiveOrganizationUuid() },
    })
    .then((response) => response.data?.identifier as string);
};

export const deleteWmsDocumentItem = (itemUuid: string) => {
  return axiosInstance.delete(`/wms/document/item/${itemUuid}`);
};

export const deleteWmsDocument = (uuid: string) => {
  return axiosInstance.delete(`/wms/document/${uuid}`);
};

export const getDocumentsWouldRequirePriceChange = (documentUuid?: string) => {
  return axiosInstance
    .get(`/wms/document/${documentUuid}/avg-price-change`)
    .then((response) => response.data as IWmsDocument[]);
};
