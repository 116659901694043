import { Badge } from '@material-ui/core';
import { FC } from 'react';
import { IUser } from '../../entity/IUser';

interface Props {
  user: IUser;
  size?: number;
}

export const UserAvatar: FC<Props> = ({ user, size }) => (
  <Badge
    className="badge-dot"
    overlap="circular"
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    variant="dot">
    {/* {user.photo ?
        <Avatar
        alt="Avatar"
        style={size ? { width: `${size}px`, height: `${size}px` } : undefined}
        src={user.photo}
        />
          : */}
    <div
      className="avatar-icon-initials"
      style={size ? { width: `${size}px`, height: `${size}px` } : undefined}>
      {`${user.firstName!.charAt(0)}${user.lastName!.charAt(0)}`}
    </div>
    {/* } */}
  </Badge>
);
