import { FC, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ComponentLoader } from '../Loaders/ComponentLoader';
import { HeaderTable } from '../Header/TableHeader';
import Pagination from '../Pagination/Pagination';
import { ITableDataItem, ITableHeaderColumn } from '../../entity/ITable';
import { TableOptions } from './TableOptions';

interface Props {
  data: ITableDataItem[];
  onRowClick?: (uuid: any, event?: any) => void;
  isLoading: boolean;
  totalRows?: number;
  headerCols: ITableHeaderColumn[];
  secondHeaderRow?: ITableHeaderColumn[];
  headerWrap?: boolean;
  sortTable?: (sort?: string) => void;
  serverSidePagination?: {
    offset: number;
    limit: number;
    setLimit: (limit: number) => void;
    setOffset: (offset: number) => void;
  };
  filterData?: {
    activeFilterNo?: number;
    createElement: (onClose: () => void) => any;
    setFilter: (filter: any) => void;
    allowOverflow?: boolean;
  };
  defaultLimit?: number;
  selectedRowUuid?: string | number;
  tableTotalsData?: ITableDataItem[];
  footerAction?: Function;
  footerActionLink?: string;
  footerActionIcon?: any;
  footerActionType?: 'submit' | 'button';
  footerActionText?: string;
  inCardTable?: boolean;
  eachRowDescData?: any;
  className?: string;
}

const NewCustomTable: FC<Props> = ({
  data,
  onRowClick,
  isLoading,
  totalRows,
  headerCols,
  secondHeaderRow,
  headerWrap,
  sortTable,
  serverSidePagination,
  filterData,
  selectedRowUuid,
  defaultLimit,
  tableTotalsData,
  footerAction,
  footerActionLink,
  footerActionIcon,
  footerActionType,
  footerActionText,
  inCardTable,
  eachRowDescData,
  className,
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(!!defaultLimit ? defaultLimit : 5);
  const { t } = useTranslation();

  return (
    <div
      className={clsx('custom-table', {
        'mb-3': !inCardTable,
        'table-no-border-radius': inCardTable,
      })}>
      {!!filterData && (
        <TableOptions filterData={filterData} allowOverflow={filterData?.allowOverflow} />
      )}
      <TableContainer component={Paper}>
        <Table
          aria-label="custom pagination table"
          className={clsx(className, { 'overlay-loading': isLoading })}>
          <HeaderTable
            headerCols={headerCols}
            headerWrap={headerWrap}
            sortTable={sortTable}
            secondHeaderRow={secondHeaderRow}
          />
          <TableBody>
            {(!serverSidePagination && rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, rb) => (
              <>
                <TableRow
                  hover={!!onRowClick && !!row.uuid?.value}
                  className={clsx({
                    'custom-table-row-clickable': !!onRowClick && !!row.uuid?.value,
                  })}
                  selected={
                    selectedRowUuid ? selectedRowUuid === row.uuid.value : undefined
                  }
                  onClick={(event) =>
                    !!onRowClick && !!row.uuid.value && onRowClick(row.uuid.value, event)
                  }
                  key={!!row.uuid?.value ? row.uuid.value : rb}>
                  {headerCols.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      hidden={column.hidden}
                      className={clsx({
                        [row[column.id].class?.name || '']: row[column.id].class?.active,
                      })}>
                      {row[column.id].value}
                    </TableCell>
                  ))}
                </TableRow>
                {!!eachRowDescData && !!eachRowDescData[row.uuid?.value] && (
                  <TableRow>
                    <TableCell colSpan={headerCols.length}>
                      {eachRowDescData[row.uuid.value]}
                    </TableCell>
                  </TableRow>
                )}
              </>
            ))}
            {data.length <= 0 && (
              <TableRow>
                <TableCell colSpan={headerCols.length}>{t('NoResult')}</TableCell>
              </TableRow>
            )}
            {tableTotalsData?.map((row, rb) => (
              <TableRow key={rb}>
                {headerCols.map(({ id: columnId }) => (
                  <TableCell
                    key={columnId}
                    align={row[columnId]?.align || 'right'}
                    hidden={row[columnId]?.hidden}
                    colSpan={row[columnId]?.span}>
                    <strong>{row[columnId]?.value}</strong>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>

          <TableFooter>
            <Pagination
              totalRows={totalRows ? totalRows : data.length}
              limit={!!serverSidePagination ? serverSidePagination.limit : rowsPerPage}
              offset={!!serverSidePagination ? serverSidePagination.offset : page}
              setLimit={
                !!serverSidePagination ? serverSidePagination.setLimit : setRowsPerPage
              }
              setOffset={
                !!serverSidePagination ? serverSidePagination.setOffset : setPage
              }
              footerAction={footerAction}
              footerActionLink={footerActionLink}
              footerActionIcon={footerActionIcon}
              footerActionType={footerActionType}
              footerActionText={footerActionText}
            />
          </TableFooter>
        </Table>
        <ComponentLoader isLoading={isLoading} />
      </TableContainer>
    </div>
  );
};

export default NewCustomTable;
