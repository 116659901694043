import { FC } from 'react';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import './assets/base.scss';
import './style/App.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './utils/ScrollToTop';
import Routes from './Routes';

library.add(far, fab, fas);

const store = configureStore();
// export const storeDispatch = store.dispatch;

const App: FC = () => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter basename="/">
        <ScrollToTop>
          <ToastContainer theme="colored" />
          <Routes />
        </ScrollToTop>
      </BrowserRouter>
    </Provider>
  </I18nextProvider>
);

export default App;
