import { lazy, Suspense } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';
import { Redirect, withRouter } from 'react-router';

import { LazyLoader } from './components/Loaders/LazyLoader';
import MuiTheme from './theme';
import MainPage from './pages/MainPage';
import LogIn from './pages/LogIn/Login';

const WrongOrganization = lazy(() => import('./pages/Error/WrongOrganization'));
const UsersPage = lazy(() => import('./pages/Administration/Users/UsersPage'));
const OrganizationsPage = lazy(
  () => import('./pages/Administration/Organizations/OrganizationsPage')
);
const OrganizationalUnits = lazy(
  () => import('./pages/Administration/OrganizationalUnits/OrganizationalUnitsPage')
);
const UnitsOfMeasure = lazy(() => import('./pages/Wms/UnitsOfMeasure/UnitsOfMeasure'));
const Classifications = lazy(() => import('./pages/Wms/Classifications/Classifications'));
const MainProductPage = lazy(() => import('./pages/Wms/MainProduct/MainProductPage'));
const ClassificationTypes = lazy(
  () => import('./pages/Wms/ClassificationTypes/ClassificationTypes')
);
const Administration = lazy(
  () => import('./pages/Administration/HomePageAdministration')
);
const Wms = lazy(() => import('./pages/Wms/HomePageWms'));
const WmsDocumentsPage = lazy(() => import('./pages/Wms/Documents/WmsDocumentsPage'));
const SIDocumentsPage = lazy(() => import('./pages/SI/Documents/SIDocumentsPage'));
const WmsDocumentsEditPage = lazy(
  () => import('./pages/Wms/Documents/Edit/WmsDocumentsEditPage')
);
const SIDocumentsEditPage = lazy(
  () => import('./pages/SI/Documents/SIDocumentsEditPage')
);

const SISupplyPrintReport = lazy(
  () => import('./pages/SI/Supplies/Report/SupplyPrintReport')
);
const Warehouses = lazy(() => import('./pages/Wms/Warehouses/Warehouses'));
const SIWarehouses = lazy(() => import('./pages/SI/Warehouses/SIWarehouses'));
const WarehouseEdit = lazy(() => import('./pages/Wms/WarehouseEdit/WarehouseEdit'));
const WarehouseAddNew = lazy(() => import('./pages/Wms/WarehouseAddNew/WarehouseAddNew'));
const SIWarehousesAddNew = lazy(() => import('./pages/SI/Warehouses/SIWarehouseAddNew'));

const Assets = lazy(() => import('./pages/Assets/HomePageAssets'));
const SI = lazy(() => import('./pages/SI/HomePageSI'));

const FixedAssetsPage = lazy(() => import('./pages/Assets/FixedAssets/FixedAssetsPage'));
const FixedAssetEditPage = lazy(
  () => import('./pages/Assets/FixedAssets/Edit/FixedAssetEditPage')
);
const FADocumentsPage = lazy(() => import('./pages/Assets/FADocuments/FADocumentsPage'));
const FADocumentEditPage = lazy(
  () => import('./pages/Assets/FADocuments/Edit/FADocumentEditPage')
);
const EmployeesPage = lazy(
  () => import('./pages/Administration/Employees/EmployeesPage')
);
const AssetLists = lazy(() => import('./pages/Assets/AssetLists/AssetLists'));
const AssetListRecapitulation = lazy(
  () => import('./pages/Assets/AssetLists/Recapitulation/AssetListRecapitulation')
);
const ProcessedAssetListRecapitulation = lazy(
  () =>
    import('./pages/Assets/AssetLists/Recapitulation/ProcessedAssetListRecapitulation')
);
const AssetListEditPage = lazy(
  () => import('./pages/Assets/AssetLists/Edit/AssetListEditPage')
);
const AmortizationGroup = lazy(
  () => import('./pages/Assets/AmortizationGroup/AmortizationGroup')
);
const FixedAssetsPrint = lazy(
  () => import('./pages/Assets/FixedAssets/Print/FixedAssetsPrint')
);
const FixedAssetRecapitulationReport = lazy(
  () => import('./pages/Assets/Report/FixedAssetRecapitulationReport')
);
const DeprecationCalculationsPage = lazy(
  () => import('./pages/Assets/DeprecationCalculation/DeprecationCalculationPage')
);
const DeprecationCalculationRecapitulationReport = lazy(
  () => import('./pages/Assets/Report/DeprecationCalculationRecapitulationReport')
);
const ReportsPage = lazy(() => import('./pages/Assets/Report/ReportsPage'));
const AuditLogsPage = lazy(() => import('./pages/SI/AuditLogs/AuditLogsPage'));
const DocumentTypesPage = lazy(() => import('./pages/SI/DocumentTypes/DocumentTypes'));
const SIReportsPage = lazy(() => import('./pages/SI/Supplies/Report/ReportsPage'));
const FixedAssetFinancialRecapitulationReport = lazy(
  () =>
    import(
      './pages/Assets/FixedAssets/Edit/FinancialCardTab/FixedAssetFinancialRecapitulationReport'
    )
);
const GlassType = lazy(() => import('./pages/Assets/GlassType/GlassType'));
const LegalEntities = lazy(
  () => import('./pages/Administration/LegalEntities/LegalEntities')
);
const EditLegalEntity = lazy(
  () => import('./pages/Administration/LegalEntities/EditLegalEntity')
);
const DocumentRecapitulation = lazy(
  () => import('./pages/Assets/FADocuments/Edit/Print/FADocumentPrint')
);
const ContractsPage = lazy(() => import('./pages/Contracts/Contracts/ContractsPage'));
const WMSProductsPage = lazy(() => import('./pages/Wms/Products/WmsProductsPage'));
const SIProductsPage = lazy(() => import('./pages/SI/Product/SIProductsPage'));
const SIAddProductForm = lazy(() => import('./pages/SI/Product/SIAddProductForm'));
const ProductCardPage = lazy(
  () => import('./pages/Wms/Products/ProductCard/ProductCardPage')
);
const SIProductCard = lazy(() => import('./pages/SI/Product/SIProductCard'));
const SIProductFinancialCard = lazy(
  () => import('./pages/SI/Product/SIProductFinancialCard')
);
const SIProductStockCard = lazy(() => import('./pages/SI/Product/SIProductStockCard'));

const EditContractPage = lazy(
  () => import('./pages/Contracts/Contracts/Edit/EditContractPage')
);
const CurrencyPage = lazy(() => import('./pages/Coodebooks/Currency/CurrencyPage'));
const WarrantyPage = lazy(() => import('./pages/Contracts/Warranty/WarrantyPage'));
const ManufacturerPage = lazy(
  () => import('./pages/Coodebooks/Manufacturer/ManufacturerPage')
);
const CostCenterPage = lazy(() => import('./pages/Coodebooks/CostCenter/CostCenterPage'));
const LegalEntitiesGroupsPage = lazy(
  () => import('./pages/Coodebooks/LegalEntitiesGroups/LegalEntitiesGroupsPage')
);
const UserForm = lazy(() => import('./pages/Administration/Users/UserForm'));
const SuppliesPage = lazy(() => import('./pages/Wms/Supplies/SuppliesPage'));
const SISuppliesPage = lazy(() => import('./pages/SI/Supplies/SISupplies'));

const StockListPrintReport = lazy(
  () => import('./pages/Wms/Supplies/StockListPrintReport')
);
const WmsDocumentPrintReport = lazy(
  () => import('./pages/Wms/Documents/Edit/WmsDocumentPrintReport')
);
const LocationsPage = lazy(() => import('./pages/Assets/Location/LocationsPage'));
const AccountsPage = lazy(() => import('./pages/Finance/Account/AccountsPage'));
const EditDeprecationCalculation = lazy(
  () => import('./pages/Assets/DeprecationCalculation/Edit/EditDeprecationCalculation')
);

const Procurement = lazy(() => import('./pages/Procurement/HomePageProcurement'));
const ProcurementPlansPage = lazy(
  () => import('./pages/Procurement/Plans/ProcurementPlansPage')
);
const ProcurementPlanPartsPage = lazy(
  () => import('./pages/Procurement/PlanPart/ProcurementPlanPartsPage')
);
const ProcurementPlanEditPage = lazy(
  () => import('./pages/Procurement/Plans/Edit/ProcurementPlanEditPage')
);
const ProcurementPlanPrint = lazy(
  () => import('./pages/Procurement/Plans/ProcurementPlanPrint')
);
const ProcurementEstimateGroupsPage = lazy(
  () =>
    import('./pages/Procurement/Estimates/EstimateGroups/ProcurementEstimateGroupsPage')
);
const ProcurementEstimateGroupEditPage = lazy(
  () =>
    import(
      './pages/Procurement/Estimates/EstimateGroups/Edit/ProcurementEstimateGroupEditPage'
    )
);
const ProcurementYearlyPlansPage = lazy(
  () => import('./pages/Procurement/YearlyPlan/ProcurementYearlyPlansPage')
);
const ProcurementYearlyPlanEditPage = lazy(
  () => import('./pages/Procurement/YearlyPlan/Edit/ProcurementYearlyPlanEditPage')
);

const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99,
    },
    in: {
      opacity: 1,
      scale: 1,
    },
    out: {
      opacity: 0,
      scale: 1.01,
    },
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4,
  };

  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route path={['/login']}>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/login" component={LogIn} />
              </motion.div>
            </Switch>
          </Route>

          {/* WMS */}
          <Route
            path={[
              '/wms',
              '/wms/documents/:documentTypeUuid',
              '/wms/document/add/:documentTypeUuid',
              '/wms/document/edit/:documentUuid',
              '/wms/document/on-document-print.html',
              '/wms/wrong-organization',
              '/wms/warehouses',
              '/wms/warehouses/configuration/:wUuid',
              '/wms/units-of-measure',
              '/wms/classification-types',
              '/wms/classifications',
              '/wms/main-products',
              '/wms/products',
              '/wms/product/:productUuid',
              '/wms/manufacturers',
              '/wms/supplies',
              '/wms/supplies/on-stock-list-print.html',
            ]}>
            <MainPage>
              <Suspense fallback={<LazyLoader />}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/wms" component={Wms} />
                    <Route
                      exact
                      path="/wms/wrong-organization"
                      component={WrongOrganization}
                    />
                    <Route exact path="/wms/warehouses" component={Warehouses} />
                    <Route
                      exact
                      path="/wms/documents/:documentTypeUuid"
                      component={WmsDocumentsPage}
                    />
                    <Route
                      exact
                      path="/wms/document/add/:documentTypeUuid"
                      component={WmsDocumentsEditPage}
                    />
                    <Route
                      exact
                      path="/wms/document/edit/:documentUuid"
                      component={WmsDocumentsEditPage}
                    />
                    <Route
                      exact
                      path="/wms/document/on-document-print.html"
                      render={() => <WmsDocumentPrintReport />}
                    />
                    <Route
                      exact
                      path="/wms/warehouses/configuration/:wUuid"
                      component={WarehouseEdit}
                    />
                    <Route
                      exact
                      path="/wms/warehouses/configuration"
                      component={WarehouseAddNew}
                    />

                    <Route
                      exact
                      path="/wms/units-of-measure"
                      component={UnitsOfMeasure}
                    />
                    <Route
                      exact
                      path="/wms/classification-types"
                      component={ClassificationTypes}
                    />
                    <Route
                      exact
                      path="/wms/classifications"
                      component={Classifications}
                    />
                    <Route exact path="/wms/main-products" component={MainProductPage} />
                    <Route exact path="/wms/products" component={WMSProductsPage} />
                    <Route
                      exact
                      path="/wms/product/:productUuid"
                      component={ProductCardPage}
                    />
                    <Route exact path="/wms/manufacturers" component={ManufacturerPage} />
                    <Route exact path="/wms/supplies" component={SuppliesPage} />
                    <Route
                      exact
                      path="/wms/supplies/on-stock-list-print.html"
                      render={() => <StockListPrintReport />}
                    />
                  </motion.div>
                </Switch>
              </Suspense>
            </MainPage>
          </Route>
          {/* Assets */}
          <Route
            path={[
              '/assets',
              '/assets/wrong-organization',
              '/assets/fixed-assets',
              '/assets/fixed-assets/recapitulation-print.html',
              '/assets/fixed-assets/configuration',
              '/assets/fixed-assets/configuration/:assetUuid',
              '/assets/fixed-assets/configuration/:assetUuid/recapitulation-print.html',
              '/assets/fixed-assets/documents/:documentTypeId',
              '/assets/fixed-assets/document/:documentUuid/preview',
              '/assets/fixed-assets/document/:documentTypeId/create',
              '/assets/fixed-assets/document/:documentUuid',
              '/assets/fixed-assets/document/:documentUuid/recapitulation-print.html',
              '/assets/location',
              '/assets/asset-lists',
              '/assets/asset-list/:assetListUuid',
              '/assets/amortization-groups',
              '/assets/amortization-tax-groups',
              '/assets/glass-types',
              '/assets/legal-entities',
              '/assets/legal-entity-create',
              '/assets/legal-entities/:legalEntityUuid',
              '/assets/accounts',
              '/assets/units-of-measure',
            ]}>
            <MainPage>
              <Suspense fallback={<LazyLoader />}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/assets" component={Assets} />
                    <Route
                      exact
                      path="/assets/wrong-organization"
                      component={WrongOrganization}
                    />
                    <Route
                      exact
                      path="/assets/fixed-assets"
                      component={FixedAssetsPage}
                    />
                    <Route
                      exact
                      path="/assets/report/index.html"
                      component={ReportsPage}
                    />
                    <Route
                      exact
                      path="/assets/report/assets-print.html"
                      render={(props) => <FixedAssetsPrint {...props} />}
                    />
                    <Route
                      exact
                      path="/assets/report/recapitulation-print.html"
                      render={(props) => <FixedAssetRecapitulationReport {...props} />}
                    />
                    <Route
                      exact
                      path="/assets/report/deprecation-calculation-recapitulation-print.html"
                      render={(props) => (
                        <DeprecationCalculationRecapitulationReport {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/assets/fixed-assets/configuration/:assetUuid"
                      component={FixedAssetEditPage}
                    />
                    <Route
                      exact
                      path="/assets/fixed-assets/configuration/:assetUuid/recapitulation-print.html"
                      render={() => <FixedAssetFinancialRecapitulationReport />}
                    />
                    <Route
                      exact
                      path="/assets/fixed-assets/documents/:documentTypeId"
                      component={FADocumentsPage}
                    />
                    <Route
                      exact
                      path="/assets/fixed-assets/document/:documentUuid"
                      component={FADocumentEditPage}
                    />
                    <Route
                      exact
                      path="/assets/fixed-assets/document/:documentUuid/recapitulation-print.html"
                      render={(props) => <DocumentRecapitulation {...props} />}
                    />
                    <Route
                      exact
                      path="/assets/fixed-assets/document/:documentTypeId/create"
                      component={FADocumentEditPage}
                    />
                    <Route exact path="/assets/location" component={LocationsPage} />
                    <Route exact path="/assets/asset-lists" component={AssetLists} />
                    <Route
                      exact
                      path="/assets/asset-list/:assetListUuid/recapitulation-print.html"
                      render={(props) => <AssetListRecapitulation {...props} />}
                    />
                    <Route
                      exact
                      path="/assets/asset-list/processed/:assetListUuid/recapitulation-print.html"
                      render={(props) => <ProcessedAssetListRecapitulation {...props} />}
                    />
                    <Route
                      exact
                      path="/assets/asset-list/:assetListUuid"
                      component={AssetListEditPage}
                    />
                    <Route
                      exact
                      path="/assets/deprecation-calculation"
                      component={DeprecationCalculationsPage}
                    />
                    <Route
                      exact
                      path="/assets/deprecation-calculation/:calculationUuid"
                      component={EditDeprecationCalculation}
                    />
                    <Route
                      exact
                      path="/assets/deprecation-calculation-new"
                      component={EditDeprecationCalculation}
                    />
                    <Route
                      exact
                      path="/assets/amortization-groups"
                      render={(props) => (
                        <AmortizationGroup taxGroups={false} {...props} />
                      )}
                    />
                    <Route
                      exact
                      path="/assets/amortization-tax-groups"
                      render={(props) => (
                        <AmortizationGroup taxGroups={true} {...props} />
                      )}
                    />
                    <Route exact path="/assets/glass-types" component={GlassType} />
                    <Route
                      exact
                      path="/assets/legal-entities"
                      component={LegalEntities}
                    />
                    <Route
                      exact
                      path="/assets/legal-entity-create"
                      component={EditLegalEntity}
                    />
                    <Route
                      exact
                      path="/assets/legal-entities/:legalEntityUuid"
                      component={EditLegalEntity}
                    />
                    <Route exact path="/assets/accounts" component={AccountsPage} />
                    <Route
                      exact
                      path="/assets/units-of-measure"
                      component={UnitsOfMeasure}
                    />
                  </motion.div>
                </Switch>
              </Suspense>
            </MainPage>
          </Route>
          {/* Administration */}
          <Route
            path={[
              '/administration',
              '/administration/wrong-organization',
              '/administration/users',
              '/administration/organizations',
              '/administration/organizational-units',
              '/administration/employees',
              '/administration/accounts',
              '/administration/legal-entities',
              '/administration/legal-entity-create',
              '/administration/legal-entities/:legalEntityUuid',
              '/administration/manufacturers',
              '/administration/cost-center',
            ]}>
            <MainPage>
              <Suspense fallback={<LazyLoader />}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/administration" component={Administration} />
                    <Route
                      exact
                      path="/administration/wrong-organization"
                      component={WrongOrganization}
                    />
                    <Route exact path="/administration/users" component={UsersPage} />
                    <Route exact path="/administration/user" component={UserForm} />
                    <Route
                      exact
                      path="/administration/user/:userUuid"
                      component={UserForm}
                    />
                    <Route
                      exact
                      path="/administration/organizations"
                      component={OrganizationsPage}
                    />
                    <Route
                      exact
                      path="/administration/organizational-units"
                      component={OrganizationalUnits}
                    />
                    <Route
                      exact
                      path="/administration/legal-entities"
                      component={LegalEntities}
                    />
                    <Route
                      exact
                      path="/administration/legal-entity-create"
                      component={EditLegalEntity}
                    />
                    <Route
                      exact
                      path="/administration/legal-entities/:legalEntityUuid"
                      component={EditLegalEntity}
                    />
                    <Route
                      exact
                      path="/administration/legal-entities/groups"
                      component={LegalEntitiesGroupsPage}
                    />
                    <Route
                      exact
                      path="/administration/employees"
                      component={EmployeesPage}
                    />
                    <Route
                      exact
                      path="/administration/accounts"
                      component={AccountsPage}
                    />
                    <Route
                      exact
                      path="/administration/manufacturers"
                      component={ManufacturerPage}
                    />
                    <Route
                      exact
                      path="/administration/cost-center"
                      component={CostCenterPage}
                    />
                  </motion.div>
                </Switch>
              </Suspense>
            </MainPage>
          </Route>
          {/* Contracts */}
          <Route
            path={[
              '/contracts',
              '/contracts/wrong-organization',
              '/contracts/create',
              '/contracts/:copyContractUuid/copy',
              '/contract/:contractUuid',
              '/contracts/currencies',
              '/contracts/warranties',
              '/contracts/bank-guarantee',
            ]}>
            <MainPage>
              <Suspense fallback={<LazyLoader />}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/contracts" component={ContractsPage} />
                    <Route exact path="/contracts/create" component={EditContractPage} />
                    <Route
                      exact
                      path="/contracts/:copyContractUuid/copy"
                      component={EditContractPage}
                    />
                    <Route
                      exact
                      path="/contract/:contractUuid"
                      component={EditContractPage}
                    />
                    <Route exact path="/contracts/currencies" component={CurrencyPage} />
                    <Route exact path="/contracts/warranties" component={WarrantyPage} />
                    <Route
                      exact
                      path="/contracts/bank-guarantee"
                      component={WarrantyPage}
                    />
                    <Route
                      exact
                      path="/contracts/wrong-organization"
                      component={WrongOrganization}
                    />
                  </motion.div>
                </Switch>
              </Suspense>
            </MainPage>
          </Route>

          {/* Small inventory */}
          <Route
            path={[
              '/si',
              '/si/products',
              '/si/warehouses',
              '/si/warehouses/configuration/:wUuid',
              '/si/warehouses/configuration',
              '/si/documents/:documentTypeUuid',
              '/si/product/:productUuid',
              '/si/product',
              '/si/reports/by-account-print.html',
              '/si/reports',
              '/si/audit-logs',
            ]}>
            <MainPage>
              <Suspense fallback={<LazyLoader />}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/si" component={SI} />
                    <Route exact path="/si/products" component={SIProductsPage} />
                    <Route exact path="/si/warehouses" component={SIWarehouses} />
                    <Route
                      exact
                      path="/si/warehouses/configuration/:wUuid"
                      component={WarehouseEdit}
                    />
                    <Route
                      exact
                      path="/si/warehouses/configuration"
                      component={SIWarehousesAddNew}
                    />
                    <Route
                      exact
                      path="/si/documents/:documentTypeUuid"
                      component={SIDocumentsPage}
                    />
                    <Route
                      exact
                      path="/si/document/add/:documentTypeUuid"
                      component={SIDocumentsEditPage}
                    />
                    <Route
                      exact
                      path="/si/document/edit/:documentUuid"
                      component={SIDocumentsEditPage}
                    />
                    <Route
                      exact
                      path="/si/product/:productUuid"
                      component={SIProductCard}
                    />
                    <Route
                      exact
                      path="/si/product/:productUuid/financial-card-print.html"
                      component={SIProductFinancialCard}
                    />
                    <Route
                      exact
                      path="/si/product/:productUuid/stock-card-print.html"
                      component={SIProductStockCard}
                    />
                    <Route exact path="/si/supplies" component={SISuppliesPage} />
                    <Route exact path="/si/product" component={SIAddProductForm} />
                    <Route
                      exact
                      path="/si/reports/by-account-print.html"
                      component={SISupplyPrintReport}
                    />
                    <Route exact path="/si/reports" component={SIReportsPage} />
                    <Route exact path="/si/audit-logs" component={AuditLogsPage} />
                    <Route
                      exact
                      path="/si/document-types"
                      component={DocumentTypesPage}
                    />
                  </motion.div>
                </Switch>
              </Suspense>
            </MainPage>
          </Route>

          {/* Procurement */}
          <Route
            path={[
              '/procurement',
              '/procurement/plan/parts',
              '/procurement/plans/:category',
              '/procurement/accounts',
              '/procurement/:category/plan/:planUuid',
              '/procurement/:category/plan',
              '/procurement/:category/plan/:planUuid/show-print.html',
            ]}>
            <MainPage>
              <Suspense fallback={<LazyLoader />}>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route exact path="/procurement" component={Procurement} />
                    <Route
                      exact
                      path="/procurement/plan/parts"
                      component={ProcurementPlanPartsPage}
                    />
                    <Route
                      exact
                      path="/procurement/plans/:category"
                      component={ProcurementPlansPage}
                    />
                    <Route exact path="/procurement/accounts" component={AccountsPage} />
                    <Route
                      exact
                      path="/procurement/:category/plan/:planUuid"
                      component={ProcurementPlanEditPage}
                    />
                    <Route
                      exact
                      path="/procurement/:category/plan"
                      component={ProcurementPlanEditPage}
                    />
                    <Route
                      exact
                      path="/procurement/:category/plan/:planUuid/show-print.html"
                      component={ProcurementPlanPrint}
                    />
                    <Route
                      exact
                      path="/procurement/estimate/groups"
                      component={ProcurementEstimateGroupsPage}
                    />
                    <Route
                      exact
                      path="/procurement/estimate/group/:groupUuid"
                      component={ProcurementEstimateGroupEditPage}
                    />
                    <Route
                      exact
                      path="/procurement/estimate/group"
                      component={ProcurementEstimateGroupEditPage}
                    />
                    <Route
                      exact
                      path="/procurement/yearly-plans"
                      component={ProcurementYearlyPlansPage}
                    />
                    <Route
                      exact
                      path="/procurement/yearly-plan/:yearlyPlanUuid"
                      component={ProcurementYearlyPlanEditPage}
                    />
                    <Route
                      exact
                      path="/procurement/yearly-plan"
                      component={ProcurementYearlyPlanEditPage}
                    />
                  </motion.div>
                </Switch>
              </Suspense>
            </MainPage>
          </Route>
        </Switch>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default withRouter(Routes);
