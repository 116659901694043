import { FC } from 'react';
import { List, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITableSort } from '../../entity/ITable';
import { faArrowDownWideShort } from '../../assets/fontAwesome/light/faArrowDownWideShort';
import { faArrowDownShortWide } from '../../assets/fontAwesome/light/faArrowDownShortWide';

interface Props {
  sort: string;
  setSort: (sort: string) => void;
  sortOptions: ITableSort;
}
export const SetSortList: FC<Props> = ({ sortOptions, sort, setSort }) => {
  const { t } = useTranslation();
  return (
    <List className="flex-column">
      {Object.keys(sortOptions).map((e) => (
        <ListItem
          key={e}
          button
          className="menu-item-m"
          onClick={() =>
            setSort(
              sort === sortOptions[e].desc ? sortOptions[e].asc : sortOptions[e].desc
            )
          }>
          <div className="flex-center justify-between full-width">
            <p className="mr-5 mb-0">{t(e)}</p>
            {sort === sortOptions[e].desc && (
              <FontAwesomeIcon icon={faArrowDownWideShort} />
            )}
            {sort === sortOptions[e].asc && (
              <FontAwesomeIcon icon={faArrowDownShortWide} />
            )}
          </div>
        </ListItem>
      ))}
    </List>
  );
};
