const initState = {
  classificationTree: [],
  classification: null,
  attributes: null,
  attribute: null,
  editForm: false,
  submitFailMode: false,
  addChild: false,
  loading: false,
};

const ClassificationsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_CLASSIFICATIONS':
      return { ...state, loading: true };
    case 'FETCH_CLASSIFICATIONS_SUCCESSFUL':
      return {
        ...state,
        classificationTree: action.payload,
        classification: null,
        attributes: null,
        loading: false,
      };
    case 'FETCH_CLASSIFICATIONS_FAIL':
      return { ...state, classificationTree: [], loading: false };
    case 'FETCH_CLASSIFICATION_SUCCESSFUL':
      return {
        ...state,
        classification: action.payload,
        editForm: true,
        submitFailMode: false,
        addChild: false,
      };
    case 'FETCH_CLASSIFICATION_FAIL':
      return { ...state, classification: null, editForm: false, submitFailMode: false };
    case 'FETCH_ATTRIBUTES_SUCCESSFUL':
      return { ...state, attributes: action.payload };
    case 'FETCH_ATTRIBUTES_FAIL':
      return { ...state, attributes: null };
    case 'SAVE_CLASSIFICATION_ATTRIBUTE':
      return { ...state, attribute: action.payload };
    case 'SAVE_CLASSIFICATION_ATTRIBUTE_FAIL':
      return { ...state, attribute: null };
    case 'EDIT_CLASSIFICATION_ATTRIBUTE':
      return { ...state, attribute: action.payload };
    case 'EDIT_CLASSIFICATION_ATTRIBUTE_FAIL':
      return { ...state, attribute: null };
    case 'FETCH_ADD_CLASSIFICATION_SUCCESSFUL':
      return {
        ...state,
        classification: action.payload,
        addChild: true,
        editForm: false,
        submitFailMode: false,
      };
    case 'FETCH_ADD_CLASSIFICATION_FAIL':
      return { ...state, classification: null, addChild: false, submitFailMode: false };
    case 'CHANGE_MODE':
      return {
        ...state,
        editForm: action.payload,
        submitFailMode: false,
        classification: null,
        attributes: null,
      };
    case 'CHANGE_ADD_CHILD_MODE':
      return { ...state, addChild: action.payload, submitFailMode: false };
    case 'EDIT_CLASSIFICATION':
      return { ...state, classification: null, editForm: false, submitFailMode: false };
    case 'EDIT_CLASSIFICATION_FAIL':
      return { ...state, submitFailMode: true };
    case 'SAVE_CLASSIFICATION':
      return { ...state, submitFailMode: false };
    case 'SAVE_CLASSIFICATION_FAIL':
      return { ...state, submitFailMode: true };
    default:
      return state;
  }
};

export default ClassificationsReducer;
