const initState = {
  fistLevelGroups: [],
  /**
   * Contains only groups with taxGroup = false
   */
  amortizationGroups: [],
  /**
   * Contains only groups with taxGroup = true
   */
  amortizationTaxGroups: [],
  group: null,
  editForm: false,
  submitFailMode: false,
  loading: false,
};

const AmortizationGroupReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_AMORTIZATION_GROUPS':
      return { ...state, loading: true };
    case 'FETCH_AMORTIZATION_GROUPS_SUCCESSFUL':
      return { ...state, amortizationGroups: action.payload.groups, loading: false };
    case 'FETCH_AMORTIZATION_GROUPS_FAIL':
      return { ...state, amortizationGroups: [], loading: false };
    case 'FETCH_AMORTIZATION_TAX_GROUPS':
      return { ...state, loading: true };
    case 'FETCH_AMORTIZATION_TAX_GROUPS_SUCCESSFUL':
      return { ...state, amortizationTaxGroups: action.payload.groups, loading: false };
    case 'FETCH_AMORTIZATION_TAX_GROUPS_FAIL':
      return { ...state, amortizationTaxGroups: [], loading: false };
    case 'FETCH_FIRST_LEVEL_AMORTIZATION_GROUPS':
      return { ...state, loading: true };
    case 'FETCH_FIRST_LEVEL_AMORTIZATION_GROUPS_SUCCESSFUL':
      return { ...state, fistLevelGroups: action.payload.groups, loading: false };

    case 'FETCH_FIRST_LEVEL_AMORTIZATION_GROUPS_FAIL':
      return { ...state, fistLevelGroups: [], loading: false };
    case 'CHANGE_MODE':
      return { ...state, editForm: action.payload, submitFailMode: false, group: null };
    case 'FETCH_GROUP_SUCCESSFUL':
      return { ...state, group: action.payload, editForm: true, submitFailMode: false };
    case 'FETCH_GROUP_FAIL':
      return { ...state, group: null, editForm: false, submitFailMode: false };
    case 'EDIT_SAVE_GROUP':
      return { ...state, group: null, editForm: false, submitFailMode: false };
    case 'EDIT_SAVE_GROUP_FAIL':
      return { ...state, submitFailMode: true };
    default:
      return state;
  }
};

export default AmortizationGroupReducer;
