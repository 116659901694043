const initState = {
  units: [],
  loading: false,
};

const UnitsOfMeasureReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_UNITS':
      return { ...state, loading: true };
    case 'FETCH_UNITS_SUCCESSFUL':
      return { ...state, units: action.payload, loading: false };
    case 'FETCH_UNITS_FAIL':
      return { ...state, units: [], loading: false };
    default:
      return state;
  }
};

export default UnitsOfMeasureReducer;
