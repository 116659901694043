import { FC } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton, Box, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import projectLogo from '../../assets/images/inceptionlogo.png';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';

interface Props {
  sidebarToggleMobile: boolean;
  setSidebarToggleMobile: Function;
}
const SidebarHeader: FC<Props> = ({ sidebarToggleMobile, setSidebarToggleMobile }) => {
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const { t } = useTranslation();

  return (
    <div className={'app-sidebar-header'}>
      <Box className="header-logo-wrapper" title="Inception">
        <Link
          to={
            sessionStorage.getItem('module')
              ? `/${sessionStorage.getItem('module')}`
              : '/administration'
          }
          className="header-logo-wrapper-link">
          <IconButton color="primary" size="medium" style={{ paddingRight: 0 }}>
            <img className="app-sidebar-logo" alt="Inception" src={projectLogo} />
          </IconButton>
        </Link>
        <Box className="header-logo-text" style={{ color: '#263055' }}>
          Inception
        </Box>
      </Box>
      <Box className="app-sidebar-header-btn-mobile">
        <Tooltip title={t('ToggleSidebar') as string} placement="right">
          <IconButton color={'default'} onClick={toggleSidebarMobile} size="medium">
            {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
          </IconButton>
        </Tooltip>
      </Box>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: boolean) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader);
