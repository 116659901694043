const initState = {
  orunsTree: [],
  organizationalUnit: null,
  attributes: null,
  attribute: null,
  editForm: false,
  submitFailMode: false,
  addChild: false,
  loading: false,
};

const OrganizationalUnitsReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_ORUNS':
      return { ...state, loading: true };
    case 'FETCH_ORUNS_SUCCESSFUL':
      return {
        ...state,
        orunsTree: action.payload,
        organizationalUnit: null,
        attributes: null,
        loading: false,
      };
    case 'FETCH_ORUNS_FAIL':
      return { ...state, orunsTree: [], loading: false };
    case 'FETCH_ORGANIZATIONAL_UNIT_SUCCESSFUL':
      return {
        ...state,
        organizationalUnit: action.payload,
        editForm: true,
        submitFailMode: false,
        addChild: false,
      };
    case 'FETCH_ORGANIZATIONAL_UNIT_FAIL':
      return {
        ...state,
        organizationalUnit: null,
        editForm: false,
        submitFailMode: false,
      };
    case 'FETCH_ADD_ORGANIZATIONAL_UNIT_SUCCESSFUL':
      return {
        ...state,
        organizationalUnit: action.payload,
        addChild: true,
        editForm: false,
        submitFailMode: false,
      };
    case 'FETCH_ADD_ORGANIZATIONAL_UNIT_FAIL':
      return {
        ...state,
        organizationalUnit: null,
        addChild: false,
        submitFailMode: false,
      };
    case 'CHANGE_MODE':
      return {
        ...state,
        editForm: action.payload,
        submitFailMode: false,
        organizationalUnit: null,
        attributes: null,
      };
    case 'CHANGE_ADD_CHILD_MODE':
      return { ...state, addChild: action.payload, submitFailMode: false };
    case 'SAVE_EDIT_ORGANIZATION_UNIT':
      return { ...state, submitFailMode: true };
    case 'EDIT_ORGANIZATIONAL_UNIT_SUCCESSFUL':
      return {
        ...state,
        organizationalUnit: null,
        editForm: false,
        submitFailMode: false,
      };
    case 'EDIT_ORGANIZATIONAL_UNIT_FAIL':
      return { ...state, submitFailMode: true };
    case 'SAVE_ORGANIZATIONAL_UNIT_SUCCESSFUL':
      return { ...state, submitFailMode: false };
    case 'SAVE_ORGANIZATIONAL_UNIT_FAIL':
      return { ...state, submitFailMode: true };
    default:
      return state;
  }
};

export default OrganizationalUnitsReducer;
