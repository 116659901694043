const initState = {
  classificationTypes: [],
  classificationType: null,
  editForm: false,
  submitFailMode: false,
  loading: false,
};

const ClassificationTypesReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_CLASSIFICATION_TYPES':
      return { ...state, loading: true };
    case 'FETCH_CLASSIFICATION_TYPES_SUCCESSFUL':
      return { ...state, classificationTypes: action.payload, loading: false };
    case 'FETCH_CLASSIFICATION_TYPES_FAIL':
      return { ...state, classificationTypes: [], loading: false };
    case 'CHANGE_MODE':
      return {
        ...state,
        editForm: action.payload,
        submitFailMode: false,
        classificationType: null,
      };
    case 'FETCH_CLASSIFICATION_TYPE_SUCCESSFUL':
      return {
        ...state,
        classificationType: action.payload,
        editForm: true,
        submitFailMode: false,
      };
    case 'FETCH_CLASSIFICATION_TYPE_FAIL':
      return {
        ...state,
        classificationType: null,
        editForm: false,
        submitFailMode: false,
      };
    case 'EDIT_CLASSIFICATION_TYPE':
      return {
        ...state,
        classificationType: null,
        editForm: false,
        submitFailMode: false,
      };
    case 'EDIT_CLASSIFICATION_TYPE_FAIL':
      return { ...state, submitFailMode: true };
    case 'SAVE_CLASSIFICATION_TYPE':
      return { ...state, submitFailMode: false };
    case 'SAVE_CLASSIFICATION_TYPE_FAIL':
      return { ...state, submitFailMode: true };
    default:
      return state;
  }
};

export default ClassificationTypesReducer;
