const initState = {
  appLoader: false,
  clientServiceOnline: false,
};

export enum AppAction {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  LOAD_START = 'LOAD_START',
  LOAD_END = 'LOAD_END',
  CHANGE_CLIENT_SERVICE_STATUS = 'CHANGE_CLIENT_SERVICE_STATUS',
}

const AppReducer = (state = initState, action: any) => {
  switch (action.type) {
    case AppAction.LOAD_START:
      return { ...state, appLoader: true };
    case AppAction.LOAD_END:
      return { ...state, appLoader: false };
    case AppAction.CHANGE_CLIENT_SERVICE_STATUS:
      return { ...state, clientServiceOnline: action.payload };
    default:
      return state;
  }
};

export default AppReducer;
