const initState = {
  areas: [],
  area: null,
  areaTypes: [],
  editForm: false,
  submitFailMode: false,
  loading: false,
};

const AreaReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_WAREHOUSE_AREAS':
      return { ...state, loading: true };
    case 'FETCH_WAREHOUSE_AREAS_SUCCESSFUL':
      return { ...state, areas: action.payload, loading: false };
    case 'FETCH_WAREHOUSE_AREAS_FAIL':
      return { ...state, areas: [], loading: false };
    case 'FETCH_AREA_SUCCESSFUL':
      return { ...state, area: action.payload, editForm: true, submitFailMode: false };
    case 'FETCH_AREA_FAIL':
      return { ...state, area: null, editForm: false, submitFailMode: false };
    case 'CHANGE_MODE':
      return {
        ...state,
        editForm: action.payload,
        submitFailMode: false,
        storageUnit: null,
      };
    case 'EDIT_SAVE_WAREHOUSE_AREA':
      return { ...state, submitFailMode: false };
    case 'EDIT_SAVE_WAREHOUSE_AREA_FAIL':
      return { ...state, submitFailMode: true };
    case 'FETCH_AREA_TYPES_SUCCESSFUL':
      return { ...state, areaTypes: action.payload };
    case 'FETCH_AREA_TYPES_FAIL':
      return { ...state, areaTypes: [] };
    default:
      return state;
  }
};

export default AreaReducer;
