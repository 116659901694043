import axios from 'axios';
import { IFixedAsset } from '../entity/asset/IFixedAsset';

export default class ClientAppRepository {
  axiosClient: any;

  constructor(clientAppPort: number) {
    this.axiosClient = axios.create({
      baseURL: `http://localhost:${clientAppPort}`,
    });
  }

  getAvailablePrinters() {
    return this.axiosClient.get('/printing/printers');
  }

  printBarcodes(assets: IFixedAsset[]) {
    const data = {
      items: assets,
    };
    return this.axiosClient.post('/printing/print', data);
  }

  acknowledge() {
    return this.axiosClient.get('/', {
      timeout: 1000,
    });
  }
}
