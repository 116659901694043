import { ChangeEvent, FC, useState } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Hidden, IconButton, AppBar, Box, Tooltip } from '@material-ui/core';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';
import MenuRoundedIcon from '@material-ui/icons/MenuRounded';
import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import projectLogo from '../../assets/images/inceptionlogo.png';
import HeaderLogo from './HeaderLogo';
import { useTranslation } from 'react-i18next';
import { IUser } from '../../entity/IUser';
import { HeaderUserBox } from './HeaderUserBox';
import { CustomSelect } from '../InputFields/CustomSelect';

interface Props {
  activeUser: IUser;
  loggedUserModules: string[];
  history: any;
  readyForPrint: boolean;
  reportPage: boolean;
  headerShadow: any;
  headerFixed: any;
  sidebarToggleMobile: any;
  setSidebarToggleMobile: any;
  isCollapsedLayout?: any;
}
const MainHeader: FC<Props> = ({
  activeUser,
  loggedUserModules,
  history,
  readyForPrint,
  reportPage,
  headerShadow,
  headerFixed,
  sidebarToggleMobile,
  setSidebarToggleMobile,
  isCollapsedLayout,
}) => {
  const { t } = useTranslation();
  const [activeModule, setActiveModule] = useState(
    history.location.pathname.split('/')[1]
  );
  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };
  const VERSION =  process.env.REACT_APP_VERSION || '-';

  const handleModuleChange = (event: ChangeEvent<{ value: unknown }>) => {
    localStorage.setItem(`${activeUser.uuid}-module`, event.target.value as string);
    sessionStorage.setItem('module', event.target.value as string);

    history.push(`/${event.target.value}`);
    window.location.reload();
  };

  const hasActiveModule = loggedUserModules.filter((module: any) => {
    return module.toLowerCase() === activeModule;
  });

  if (!hasActiveModule.length && loggedUserModules.length) {
    setActiveModule(loggedUserModules[0].toLowerCase());
    history.push(`/${activeModule}`);
  }

  localStorage.setItem(`${activeUser.uuid}-module`, activeModule);
  sessionStorage.setItem('module', activeModule);

  return (
    <AppBar
      className={clsx('app-header', {
        'app-ready-for-printing': readyForPrint,
        'app-report-page-hidden-element': reportPage,
      })}
      style={{ backgroundColor: '#508CB4' }}
      position={headerFixed ? 'fixed' : 'absolute'}
      elevation={headerShadow ? 11 : 3}>
      {!isCollapsedLayout && <HeaderLogo />}
      <Box className="app-header-toolbar" style={{ display: 'flex', float: 'left' }}>
        <Hidden lgUp>
          <Box className="app-logo-wrapper" title="Inception">
            <Link
              to={
                sessionStorage.getItem('module')
                  ? `/${sessionStorage.getItem('module')}`
                  : '/administration'
              }
              className="app-logo-link">
              <IconButton color="primary" size="medium">
                <img className="app-logo-img" alt="Inception" src={projectLogo} />
              </IconButton>
            </Link>
            <Hidden smDown>
              <Box className="app-logo-text" color={'#263055'}>
                Inception
              </Box>
            </Hidden>
          </Box>
        </Hidden>
        <Box className="app-header-toggle-btn">
          <Tooltip title={t('ToggleSidebar') as string} placement="right">
            <IconButton color={'default'} onClick={toggleSidebarMobile} size="medium">
              {sidebarToggleMobile ? <MenuOpenRoundedIcon /> : <MenuRoundedIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box className="d-flex align-items-center module-select">
          {loggedUserModules && (
            <CustomSelect
              value={loggedUserModules.length ? activeModule : ''}
              onChange={handleModuleChange}
              items={loggedUserModules.map((module) => ({
                value: module.toLocaleLowerCase(),
                label: t(module),
              }))}
              className="change-module-selector"
              notFullWidth
              hideEmptyValue={true}
            />
          )}
        </Box>
        <Box className="d-flex align-items-center">
          <Box>{VERSION}</Box>
          <Box>
            {/*<Tooltip placement="right">*/}
            <IconButton color={'default'} size="medium">
              <NotificationsActiveTwoToneIcon />
            </IconButton>
            {/*</Tooltip>*/}
          </Box>
          <Box>
            {/*<Tooltip placement="right">*/}
            <IconButton color={'default'} size="medium">
              <EqualizerIcon />
            </IconButton>
            {/*</Tooltip>*/}
          </Box>
          <HeaderUserBox />
        </Box>
      </Box>
    </AppBar>
  );
};

const mapStateToProps = (state: any) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  loggedUserOrganizations: state.activeUserStore.loggedUserOrganizations,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: boolean) => dispatch(setSidebarToggleMobile(enable)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainHeader);
