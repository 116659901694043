Object.defineProperty(exports, '__esModule', { value: true });
var prefix = 'fas';
var iconName = 'paint-brush-fine';
var width = 576;
var height = 512;
var aliases = ['paint-brush-alt'];
var unicode = 'f5a9';
var svgPathData =
  'M203.6 290.2c-6.316-1.223-12.72-2.187-19.3-2.195c-.0313 0 0 0 0 0c-27.81 0-53.99 10.87-73.58 30.61c-22.38 22.51-32.28 52.9-29.71 82.39c2.25 25.83-16.97 47-48.97 47C14.33 447.1 0 462.3 0 479.1C0 497.7 14.33 512 32 512h53.72c54.53 0 133-7.57 172.1-46.92c25.04-25.23 33.1-60.27 27.75-92.89L203.6 290.2zM559.6 16.41c-21.88-21.88-57.31-21.88-79.19 0L227.7 269.1l79.19 79.19l252.7-252.7C581.5 73.72 581.5 38.28 559.6 16.41z';

exports.definition = {
  prefix: prefix,
  iconName: iconName,
  icon: [width, height, aliases, unicode, svgPathData],
};

exports.faPaintBrushFine = exports.definition;
exports.prefix = prefix;
exports.iconName = iconName;
exports.width = width;
exports.height = height;
exports.ligatures = aliases;
exports.unicode = unicode;
exports.svgPathData = svgPathData;
exports.aliases = aliases;
