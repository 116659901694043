import { AppAction } from '../reducers/AppReducer';

export function loadStart() {
  return function (dispatch: any) {
    dispatch({
      type: 'LOAD_START',
    });
  };
}

export function loadEnd() {
  return function (dispatch: any) {
    dispatch({
      type: 'LOAD_END',
    });
  };
}

export function changeClientServiceStatus(online: boolean) {
  return async function (dispatch: any) {
    dispatch({
      type: AppAction.CHANGE_CLIENT_SERVICE_STATUS,
      payload: online,
    });
  };
}
