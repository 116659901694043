import { FC, Fragment, useEffect } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';
import { connect } from 'react-redux';
import SidebarHeader from './SidebarHeader';
import SidebarMenu from './SidebarMenu';
import navItems from './navItems';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import { getActiveOrganizationUuid } from '../../utils/LocalStorageHelper';
import { IOrganization } from '../../entity/IOrganization';
import { IPrivileges } from '../../entity/Privileges';
import { IUser } from '../../entity/IUser';
import { IFixedAssetDocumentType } from '../../entity/asset/IFixedAssetDocumentType';
import { changeClientServiceStatus } from '../../actions/AppActions';
import ClientAppRepository from '../../repository/ClientAppRepository';
import { IWmsDocumentType } from '../../entity/wms/IWmsDocument';
// import { IBadges } from '../../entity/einvoice/IBadges';

interface Props {
  setSidebarToggleMobile: Function;
  sidebarToggleMobile: boolean;
  sidebarToggleDesktop: boolean;
  sidebarFixed: boolean;
  userInfo: IUser;
  privileges: IPrivileges;
  documentTypes: IFixedAssetDocumentType[];
  wmsDocumentTypes: IWmsDocumentType[];
  loggedUserOrganizations: IOrganization[];
  readyForPrint: boolean;
  reportPage: boolean;
  changeClientServiceStatus: (online: boolean) => void;
}

const Sidebar: FC<Props> = ({
  setSidebarToggleMobile,
  sidebarToggleMobile,
  sidebarToggleDesktop,
  sidebarFixed,
  userInfo,
  privileges,
  documentTypes,
  wmsDocumentTypes,
  loggedUserOrganizations,
  readyForPrint,
  reportPage,
  changeClientServiceStatus,
}) => {
  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);
  // const [badges, setBadges] = useState<IBadges>({});
  const activeOrganization = loggedUserOrganizations.find(
    (organization: IOrganization) => organization.uuid === getActiveOrganizationUuid()
  );

  useEffect(() => {
    const clientAppPort = userInfo.clientAppPort;
    if (clientAppPort) {
      const repo = new ClientAppRepository(clientAppPort);
      repo
        .acknowledge()
        .then(() => {
          changeClientServiceStatus(true);
        })
        .catch(() => {
          console.warn('Client app unreachable.');
          changeClientServiceStatus(false);
        });
    } else {
      changeClientServiceStatus(false);
    }
  }, [userInfo.clientAppPort, changeClientServiceStatus]);

  const SidebarMenuContent: FC = () => (
    <PerfectScrollbar>
      {!!activeOrganization &&
        navItems(documentTypes, wmsDocumentTypes, privileges /*, badges*/).map((list) => (
          <SidebarMenu
            component="div"
            key={list.label}
            pages={list.content}
            title={list.label}
            documentTypes={documentTypes}
          />
        ))}
    </PerfectScrollbar>
  );

  return (
    <Fragment>
      <Hidden mdDown>
        <Paper
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-fixed': sidebarFixed,
            'app-sidebar-hidden': sidebarToggleDesktop,
            'app-ready-for-printing': readyForPrint,
            'app-report-page-hidden-element': reportPage,
          })}
          square
          elevation={3}>
          <SidebarHeader />
          <div className={clsx({ 'app-sidebar-menu': sidebarFixed })}>
            <SidebarMenuContent />
          </div>
        </Paper>
      </Hidden>

      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <SidebarMenuContent />
        </Drawer>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarToggleDesktop: state.ThemeOptions.sidebarToggleDesktop,
  loggedUserOrganizations: state.activeUserStore.loggedUserOrganizations,
  loggedUserModules: state.activeUserStore.loggedUserModules,
});

const mapDispatchToProps = (dispatch: any) => ({
  setSidebarToggleMobile: (enable: boolean) => dispatch(setSidebarToggleMobile(enable)),
  changeClientServiceStatus: (online: boolean) =>
    dispatch(changeClientServiceStatus(online)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
