import { Button, TableCell, TablePagination, TableRow } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import { ChangeEvent, FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

interface Props {
  totalRows: number;
  limit: number;
  offset: number;
  setLimit: (limit: number) => void;
  setOffset: (offset: number) => void;
  footerAction?: Function;
  footerActionLink?: string;
  footerActionIcon?: any;
  footerActionType?: 'submit' | 'button';
  footerActionText?: string;
  customLimit?: (number | { value: number; label: string })[];
}

const Pagination: FC<Props> = ({
  totalRows,
  limit,
  offset,
  setLimit,
  setOffset,
  footerAction,
  footerActionLink,
  footerActionIcon = <Refresh fontSize={'small'} />,
  footerActionType = 'button',
  footerActionText,
  customLimit,
}) => {
  const { t } = useTranslation();
  const h = useHistory();

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    totalRows ? setLimit(Math.floor(+event.target.value)) : setLimit(1);
    setOffset(0);
  };

  const handleChangePage = (
    event: MouseEvent<HTMLButtonElement> | null,
    newOffset: number
  ) => {
    setOffset(newOffset);
  };

  return (
    <TableRow>
      {(!!footerAction || !!footerActionLink) && (
        <TableCell colSpan={3}>
          <Button
            type={footerActionType}
            variant="contained"
            color="default"
            size="small"
            onClick={(event?) => {
              !!footerAction && footerAction(event);
              !!footerActionLink && h.push(footerActionLink);
            }}>
            {footerActionText ? footerActionText : footerActionIcon}
          </Button>
        </TableCell>
      )}
      {!!totalRows && (
        <TablePagination
          className="custom-pagination"
          rowsPerPageOptions={customLimit ? customLimit : [5, 10, 25, 50, 100]}
          /*** CL example ***/
          // customLimit={[
          //   1,
          //   5,
          //   10,
          //   25,
          //   {
          //     label: t('AllRows'),
          //     value: totalRows,
          //   },
          // ]}
          count={totalRows}
          //   colSpan={
          //     props.lessThanFourRowsTable ? numberOfHeaderColumns : numberOfHeaderColumns - 2
          //   }
          rowsPerPage={limit}
          labelRowsPerPage={t('NumberOfRows')}
          page={offset}
          SelectProps={{
            inputProps: { 'aria-label': 'rows per page' },
            native: true,
          }}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableRow>
  );
};

export default Pagination;
