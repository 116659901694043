import ThemeOptions from './ThemeOptions';
import AppReducer from './AppReducer';
import UnitsOfMeasureReducer from '../pages/Wms/UnitsOfMeasure/UnitsOfMeasureReducer';
import ActiveUserReducer from './ActiveUserReducer';
import ClassificationsReducer from '../pages/Wms/Classifications/ClassificationsReducer';
import ClassificationTypesReducer from '../pages/Wms/ClassificationTypes/ClassificationTypesReducer';
import StorageUnitsReducer from '../pages/Wms/WarehouseEdit/StorageUnits/StorageUnitsReducer';
import OrganizationalUnitsReducer from '../pages/Administration/OrganizationalUnits/OrganizationalUnitsReducer';
import AreaReducer from '../pages/Wms/WarehouseEdit/Areas/AreaReducer';
import AssetListsReducer from '../pages/Assets/AssetLists/AssetListsReducer';
import AmortizationGroupReducer from '../pages/Assets/AmortizationGroup/AmortizationGroupReducer';
import GlassTypeReducer from '../pages/Assets/GlassType/GlassTypeReducer';
import NotesReducer from '../pages/Administration/LegalEntities/Notes/NotesReducer';

const reducers = {
  ThemeOptions,
  appStore: AppReducer,
  unitsOfMeasureStore: UnitsOfMeasureReducer,
  activeUserStore: ActiveUserReducer,
  classificationsStore: ClassificationsReducer,
  classificationTypesStore: ClassificationTypesReducer,
  storageUnitsStore: StorageUnitsReducer,
  organizationalUnitsStore: OrganizationalUnitsReducer,
  areasStore: AreaReducer,
  assetListsStore: AssetListsReducer,
  amortizationGroupStore: AmortizationGroupReducer,
  glassTypesStore: GlassTypeReducer,
  notesStore: NotesReducer,
};

export default reducers;
