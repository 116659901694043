import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import axiosInstance from '../../apis/arp';
import queryString from 'query-string';
import {
  getActiveOrganizationUuid,
  setActiveOrganization,
} from '../../utils/LocalStorageHelper';
import { parseJwt } from '../../utils/TokenUtilsHelper';
import { FC, FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// function Copyright() {
//     return (
//         <Typography variant="body2" color="textSecondary" align="center">
//             {'Copyright © '}
//             <Link color="inherit" href="https://material-ui.com/">
//                 Your Website
//             </Link>{' '}
//             {new Date().getFullYear()}
//             {'.'}
//         </Typography>
//     );
// }

interface IProps {
  history: any;
  location: any;
}

const SignIn: FC<IProps> = ({ history, location }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (localStorage.getItem('token') !== null) {
      history.push(
        sessionStorage.getItem('module')
          ? `/${sessionStorage.getItem('module')}`
          : '/administration'
      );
    }
  }, [history]);

  const handleClick = (event: FormEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const formData = new FormData();
    formData.set('username', username);
    formData.set('password', password);

    axiosInstance
      .post(`/auth/token`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          notoast: true,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem('token', token);

          const userUuid = parseJwt(token).userUuid;

          axiosInstance
            .get(`/user/me/organizations`)
            .then((response) => {
              let belongToOrganization: boolean = false;

              if (!!getActiveOrganizationUuid()) {
                for (let org of response.data) {
                  if (getActiveOrganizationUuid() === org.uuid) {
                    belongToOrganization = true;
                    break;
                  }
                }
              }
              if (!belongToOrganization) {
                setActiveOrganization(response.data[0]);
              }
              const values = queryString.parse(location.search);

              history.push(
                values.nextUrl
                  ? values.nextUrl
                  : localStorage.getItem(`${userUuid}-module`)
                  ? `/${localStorage.getItem(`${userUuid}-module`)}`
                  : sessionStorage.getItem('module')
                  ? `/${sessionStorage.getItem('module')}`
                  : '/administration'
              );
            })
            .catch(() => {});
        }
      })
      .catch(() => {});
  };

  return (
    <Container component="main" maxWidth="xs" id="login">
      <CssBaseline />
      <div className="paper">
        <Avatar>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {t('Login')}
        </Typography>
        <form className="form" noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Korisnicko ime"
            name="email"
            autoComplete="email"
            autoFocus
            onChange={(event: FormEvent) =>
              setUsername((event.target as HTMLInputElement).value)
            }
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Sifra"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={(event: FormEvent) =>
              setPassword((event.target as HTMLInputElement).value)
            }
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Zapamti"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            onClick={handleClick}>
            {t('Login')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                {t('ForgotPassword')}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      {/*<Box mt={8}>
                    <Copyright/>
                </Box>*/}
    </Container>
  );
};

export default SignIn;
