import axios, { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import { toastError, toastSuccess } from '../components/Utils/Toasts';
import i18n from '../i18n';
import { logOutWithNextUrl } from '../services/AuthServices';
import { getToken } from '../utils/LocalStorageHelper';

export const API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : '';
const axiosInstance = axios.create({
  baseURL: API_URL,
});

axiosInstance.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    const token = getToken();
    if (token !== null) {
      // Check if token expires in next 5 minutes
      if (Date.now() + 300000 > jwt_decode<any>(token).exp * 1000) {
        logOutWithNextUrl();
      } else {
        config.headers.Authorization = `Bearer ${token}`;
        return config;
      }
    } else if (
      config.url === '/auth/token' ||
      config.url === '/token' ||
      config.url!.startsWith('/stored-file/hash/') ||
      config.url!.startsWith('/pub/') ||
      (config.url!.startsWith('/document/') && config.url!.endsWith('/send/download'))
    ) {
      return config;
    } else logOutWithNextUrl();
  }
  // (error) => {
  //   console.log('error: ', error);
  //   return Promise.reject(error);
  // }
);
axiosInstance.interceptors.response.use(
  (data) => {
    if (
      (data.config.method === 'post' || data.config.method === 'put') &&
      !data.config.headers.notoast
    )
      toastSuccess();
    else if (data.config.method === 'delete') toastSuccess(i18n.t('DeletedSuccessfully'));
    return data;
  },
  (error) => {
    console.error(error);
    if (error.response?.status === 401 && window.location.pathname !== '/login') {
      return logOutWithNextUrl();
    } else if (!error.response?.data?.errors) {
      return toastError(i18n.t('NoInternetError'));
    } else {
      const errorMessage = error.response.data.errors
        .map((e: any) => (e.errorCode ? i18n.t(e.errorCode) : e.errorMessage))
        .join('\n');
      return toastError(errorMessage);
    }
    // return Promise.reject(error);
  }
);

export default axiosInstance;
