const initState = {
  userInfo: null,
  privileges: Set,
  loggedUserOrganizations: [],
  loggedUserModules: [],
  loading: false,
};

export enum ActiveUserReducerActions {
  FETCH_USER_INFO_SUCCESSFUL = 'FETCH_USER_INFO_SUCCESSFUL',
  FETCH_USER_INFO_FAIL = 'FETCH_USER_INFO_FAIL',
  FETCH_USER_PRIVILEGES = 'FETCH_USER_PRIVILEGES',
  FETCH_LOGGED_USER_ORGANIZATIONS = 'FETCH_LOGGED_USER_ORGANIZATIONS',
  FETCH_LOGGED_USER_ORGANIZATIONS_SUCCESSFUL = 'FETCH_LOGGED_USER_ORGANIZATIONS_SUCCESSFUL',
  FETCH_LOGGED_USER_ORGANIZATIONS_FAIL = 'FETCH_LOGGED_USER_ORGANIZATIONS_FAIL',
  FETCH_LOGGED_USER_MODULES = 'FETCH_LOGGED_USER_MODULES',
  FETCH_LOGGED_USER_MODULES_SUCCESSFUL = 'FETCH_LOGGED_USER_MODULES_SUCCESSFUL',
  FETCH_LOGGED_USER_MODULES_FAIL = 'FETCH_LOGGED_USER_MODULES_FAIL',
}

const ActiveUserReducer = (state = initState, action: any) => {
  switch (action.type) {
    case ActiveUserReducerActions.FETCH_USER_INFO_SUCCESSFUL:
      return {
        ...state,
        userInfo: action.payload.user,
        privileges:
          action.payload.privileges.length > 0
            ? Object.assign(
                // @ts-ignore
                ...action.payload.privileges.map((permission: string) => ({
                  [permission]: true,
                }))
              )
            : {},
      };
    case ActiveUserReducerActions.FETCH_USER_INFO_FAIL:
      return { ...state, userInfo: null };
    case ActiveUserReducerActions.FETCH_LOGGED_USER_ORGANIZATIONS:
      return { ...state, loading: true };
    case ActiveUserReducerActions.FETCH_LOGGED_USER_ORGANIZATIONS_SUCCESSFUL:
      return { ...state, loggedUserOrganizations: action.payload, loading: false };
    case ActiveUserReducerActions.FETCH_LOGGED_USER_ORGANIZATIONS_FAIL:
      return { ...state, loggedUserModules: [], loading: false };
    case ActiveUserReducerActions.FETCH_LOGGED_USER_MODULES:
      return { ...state, loading: true };
    case ActiveUserReducerActions.FETCH_LOGGED_USER_MODULES_SUCCESSFUL:
      return { ...state, loggedUserModules: action.payload, loading: false };
    case ActiveUserReducerActions.FETCH_LOGGED_USER_MODULES_FAIL:
      return { ...state, loggedUserModules: [], loading: false };
    default:
      return state;
  }
};

export default ActiveUserReducer;
