import { Checkbox, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { FC } from 'react';

interface Props {
  value: any;
  onChange: (value: any) => void;
  label?: string;
  items?: {
    value?: string | number;
    label: string;
  }[];
  required?: boolean;
  className?: string;
  notFullWidth?: boolean;
  labelId?: string;
  labelClass?: string;
  multiple?: boolean;
  renderValue?: () => string;
  disabled?: boolean;
  hideEmptyValue?: boolean;
}
export const CustomSelect: FC<Props> = ({
  label,
  value,
  onChange,
  items,
  required,
  className,
  children,
  notFullWidth,
  labelId = 'custom-select-label',
  labelClass,
  multiple,
  renderValue,
  disabled,
  hideEmptyValue,
}) => {
  if (!hideEmptyValue && !multiple && items) {
    items.unshift({ label: '-', value: undefined });
  }
  return (
    <FormControl
      required={required}
      variant="outlined"
      size="small"
      fullWidth={!notFullWidth}
      disabled={disabled}>
      {!!label && (
        <InputLabel className={labelClass} id={labelId}>
          {label}
        </InputLabel>
      )}
      <Select
        required={required}
        className={className}
        value={value}
        onChange={onChange}
        label={label}
        labelId={labelId}
        multiple={multiple}
        renderValue={renderValue}>
        {items
          ? items.map((e) => (
              <MenuItem value={e.value} key={e.label}>
                {multiple && <Checkbox checked={value.includes(e.value)} />}
                {e.label}
              </MenuItem>
            ))
          : children}
      </Select>
    </FormControl>
  );
};
