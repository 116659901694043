const initState = {
  storageUnits: [],
  storageUnit: null,
  storageUnitTypes: [],
  areaUuid: '',
  editForm: false,
  submitFailMode: false,
  loading: false,
};

const StorageUnitReducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'FETCH_WAREHOUSE_STORAGE_UNITS':
      return { ...state, loading: true };
    case 'FETCH_WAREHOUSE_STORAGE_UNITS_SUCCESSFUL':
      return { ...state, storageUnits: action.payload, loading: false };
    case 'FETCH_WAREHOUSE_STORAGE_UNITS_FAIL':
      return { ...state, storageUnits: [], loading: false };
    case 'CHANGE_STORAGE_UNIT_MODE':
      return {
        ...state,
        editForm: action.payload,
        submitFailMode: false,
        storageUnit: null,
      };
    case 'FETCH_STORAGE_UNIT_SUCCESSFUL':
      return {
        ...state,
        storageUnit: action.payload,
        editForm: true,
        submitFailMode: false,
      };
    case 'FETCH_STORAGE_UNIT_FAIL':
      return { ...state, storageUnit: null, editForm: false, submitFailMode: false };
    case 'EDIT_AREA_STORAGE_UNIT':
      return { ...state, storageUnit: null, editForm: false, submitFailMode: false };
    case 'EDIT_AREA_STORAGE_UNIT_FAIL':
      return { ...state, submitFailMode: true };
    case 'SAVE_AREA_STORAGE_UNIT':
      return { ...state, submitFailMode: false, areaUuid: action.payload };
    case 'SAVE_AREA_STORAGE_UNIT_FAIL':
      return { ...state, submitFailMode: true, areaUuid: '' };
    case 'FETCH_STORAGE_UNIT_TYPES_SUCCESSFUL':
      return { ...state, storageUnitTypes: action.payload };
    case 'FETCH_STORAGE_UNIT_TYPES_FAIL':
      return { ...state, storageUnitTypes: [] };
    default:
      return state;
  }
};

export default StorageUnitReducer;
