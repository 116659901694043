import { FC } from 'react';

interface Props {
  number?: number;
  className?: string;
  color?:
    | 'primary'
    | 'primaryLight'
    | 'primaryDark'
    | 'success'
    | 'info'
    | 'warning'
    | 'danger'
    | 'light'
    | 'dark';
}

export const CBadge: FC<Props> = ({ number, className, color = 'primary', children }) => {
  return (
    <div className="badge-parent">
      {children}
      {!!number && number > 0 && (
        <div className={`c-badge badge-${color} ${className || ''}`}>{number}</div>
      )}
    </div>
  );
};
