import { FC, useMemo, useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent/DialogContent';
import Dialog from '@material-ui/core/Dialog/Dialog';
import Button from '@material-ui/core/Button/Button';
import DialogActions from '@material-ui/core/DialogActions/DialogActions';
import i18n from '../../i18n';
import DialogTitle from '@material-ui/core/DialogTitle/DialogTitle';
import { IOrganization } from '../../entity/IOrganization';
import {
  getActiveOrganizationUuid,
  setActiveOrganization,
} from '../../utils/LocalStorageHelper';
import { ITableDataItem, ITableHeaderColumn } from '../../entity/ITable';
import { useTranslation } from 'react-i18next';
import NewCustomTable from '../Tables/newCustomTable';
import { useSelector } from 'react-redux';
import { IReduxState } from '../../entity/IRedux';
import TextField from '@material-ui/core/TextField';

interface Props {
  isOpen: boolean;
  handleClosePopover: () => void;
}

export const ChangeOrganizationDialog: FC<Props> = ({ isOpen, handleClosePopover }) => {
  const [search, setSearch] = useState('');

  const { t } = useTranslation();
  const loggedUserOrganizations = useSelector(
    (state: IReduxState) => state.activeUserStore.loggedUserOrganizations
  );

  const handleOrganizationChange = (organization: IOrganization) => {
    setActiveOrganization(organization);
    i18n.changeLanguage(organization.locale).then(() => {
      window.location.href = sessionStorage.getItem('module')
        ? `/${sessionStorage.getItem('module')}`
        : '/administration';
    });
  };

  const filteredOrganizations = useMemo(
    () =>
      loggedUserOrganizations?.filter((organization) =>
        organization.name!.toLowerCase().includes(search.toLowerCase())
      ) || [],
    [search, loggedUserOrganizations]
  );

  const headerCols: ITableHeaderColumn[] = [
    { id: 'orderNumber', label: t('OrderNumber') },
    { id: 'name', label: t('Name') },
    { id: 'registrationNumber', label: t('RegistrationNumber') },
    { id: 'taxId', label: t('TaxId') },
    { id: 'changeOrganization', label: '' },
  ];

  const data: ITableDataItem[] = filteredOrganizations?.map(
    (organization, rb: number) => ({
      uuid: {
        value: organization.uuid,
      },
      orderNumber: {
        value: `${rb + 1}.`,
      },
      name: {
        value: organization.name,
      },
      registrationNumber: {
        value: organization.registrationNumber,
      },
      taxId: {
        value: organization.taxId,
      },
      changeOrganization: {
        value:
          organization.uuid !== getActiveOrganizationUuid() ? (
            <Button
              style={{ padding: 0 }}
              onClick={() => handleOrganizationChange(organization)}>
              {t('Change')}
            </Button>
          ) : (
            ''
          ),
      },
    })
  );

  return (
    <Dialog
      open={isOpen}
      onClose={handleClosePopover}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      fullWidth={true}>
      <DialogTitle id="alert-dialog-title">
        <div className="flex justify-between align-center">
          {`${i18n.t('LoggedUserOrganizations')}`}
          <TextField
            id="search"
            label={t('Search')}
            onChange={(event) => setSearch(event.target.value)}
            variant="outlined"
            value={search}
            size={'small'}
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <NewCustomTable
          data={data}
          headerCols={headerCols}
          isLoading={false}
          inCardTable
        />
      </DialogContent>
      <DialogActions style={{ margin: 'auto' }}>
        <Button color="secondary" variant="outlined" onClick={handleClosePopover}>
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
