import i18n from './../../i18n';
import {
  Group,
  AccountBalance,
  AccountTree,
  Person,
  Dashboard,
  Business,
  ListAlt,
  DescriptionOutlined,
  MenuBook,
  TabletAndroid,
  Apartment,
  Layers,
  LayersOutlined,
  Assessment,
  History,
  Gavel,
  HorizontalSplit,
  AccessTimeSharp,
} from '@material-ui/icons';
import { IPrivileges } from '../../entity/Privileges';
import { IFixedAssetDocumentType } from '../../entity/asset/IFixedAssetDocumentType';
import { SubSystem } from '../../entity/SubSystem';
import { Companies } from '../../entity/Companies';
import { getActiveOrganization } from '../../utils/LocalStorageHelper';
import { IWmsDocumentType } from '../../entity/wms/IWmsDocument';

const navItems = (
  documentTypes: IFixedAssetDocumentType[],
  wmsDocumentTypes: IWmsDocumentType[],
  userPrivileges: IPrivileges
  //badges: IBadges
) => [
  {
    label: i18n.t('NavigationMenu'),
    content: [
      {
        label: i18n.t('HomePage'),
        description: '',
        to: '/administration',
        module: SubSystem.ADMINISTRATION,
        hasAccess: true,
        icon: Dashboard,
      },
      {
        label: i18n.t('Users'),
        description: '',
        to: '/administration/users',
        module: SubSystem.ADMINISTRATION,
        hasAccess: userPrivileges['USER_R'],
        icon: Group,
      },
      {
        label: i18n.t('Organizations'),
        description: 'Organizacije strana opis.',
        to: '/administration/organizations',
        module: SubSystem.ADMINISTRATION,
        hasAccess: userPrivileges['ORGANIZATION_R_W'],
        icon: AccountBalance,
      },
      {
        label: i18n.t('OrganizationalUnits'),
        description: 'Organizacione str strana opis.',
        to: '/administration/organizational-units',
        module: SubSystem.ADMINISTRATION,
        hasAccess: userPrivileges['ORUN_W'],
        icon: AccountTree,
      },
      {
        label: i18n.t('AddressBook'),
        description: 'Pravna lica opis.',
        to: '/administration/legal-entities',
        module: SubSystem.ADMINISTRATION,
        hasAccess: userPrivileges['LEGAL_ENTITY_W'],
        icon: Business,
      },
      {
        label: i18n.t('Employees'),
        description: 'Zaposleni opis.',
        to: '/administration/employees',
        module: SubSystem.ADMINISTRATION,
        hasAccess: userPrivileges['EMPLOYEE_W'],
        icon: Person,
      },
      {
        label: i18n.t('Codebooks'),
        hasAccess: userPrivileges['USER_R'],
        icon: MenuBook,
        module: SubSystem.ADMINISTRATION,
        content: [
          {
            label: i18n.t('Accounts'),
            hasAccess: userPrivileges['CODEBOOK_W'],
            description: 'Opis.',
            to: '/administration/accounts',
          },
          {
            label: i18n.t('LegalEntitiesGroups'),
            hasAccess: userPrivileges['LEGAL_ENTITY_W'],
            description: '',
            to: '/administration/legal-entities/groups',
          },
          {
            label: i18n.t('Manufacturers'),
            hasAccess: userPrivileges['USER_R'],
            description: 'Manufacturers.',
            to: '/administration/manufacturers',
          },
          {
            label: i18n.t('CostCenter'),
            hasAccess: userPrivileges['COST_CENTER_W'],
            description: 'CostCenter.',
            to: '/administration/cost-center',
          },
        ].filter((page) => page.hasAccess),
      },
      {
        label: i18n.t('HomePage'),
        description: 'Opis.',
        to: '/assets',
        module: SubSystem.ASSETS,
        hasAccess: true,
        icon: Dashboard,
      },
      {
        label: i18n.t('FixedAssetEvidence'),
        description: 'Opis.',
        to: '/assets/fixed-assets',
        module: SubSystem.ASSETS,
        hasAccess: userPrivileges['FIXED_ASSET_R'],
        icon: ListAlt,
      },
      {
        label: i18n.t('Documents'),
        hasAccess: userPrivileges['FIXED_ASSET_DOCUMENT_R'],
        icon: DescriptionOutlined,
        module: SubSystem.ASSETS,
        content: documentTypes.map((type: IFixedAssetDocumentType) => ({
          label: type.name,
          hasAccess: userPrivileges['FIXED_ASSET_DOCUMENT_R'],
          to: `/assets/fixed-assets/documents/${type.id}`,
        })),
      },
      {
        label: i18n.t('DeprecationCalculation'),
        description: 'Opis.',
        to: '/assets/deprecation-calculation',
        module: SubSystem.ASSETS,
        hasAccess: userPrivileges['FIXED_ASSET_R'],
        icon: History,
      },
      {
        label: i18n.t('Reports'),
        icon: Assessment,
        module: SubSystem.ASSETS,
        hasAccess: userPrivileges['FIXED_ASSET_R'],
        to: '/assets/report/index.html',
      },
      {
        label: i18n.t('Codebooks'),
        hasAccess: userPrivileges['FIXED_ASSET_LOCATION_R'],
        icon: MenuBook,
        module: SubSystem.ASSETS,
        content: [
          {
            label: i18n.t('Locations'),
            hasAccess: userPrivileges['FIXED_ASSET_LOCATION_R'],
            description: 'Opis.',
            to: '/assets/location',
          },
          {
            label: i18n.t('AmortizationGroups'),
            hasAccess: userPrivileges['FIXED_ASSET_LOCATION_R'],
            description: 'Opis.',
            to: '/assets/amortization-groups',
          },
          {
            label: i18n.t('AmortizationTaxGroups'),
            hasAccess: userPrivileges['FIXED_ASSET_LOCATION_R'],
            description: 'Opis.',
            to: '/assets/amortization-tax-groups',
          },
          getActiveOrganization()?.internalCode === Companies.GREENERY_BELGRADE
            ? {
                label: i18n.t('GlassTypes'),
                description: 'GT opis.',
                to: '/assets/glass-types',
                //hasAccess: userPrivileges['FIXED_ASSET_CODEBOOKS_R'], //todo
                hasAccess: userPrivileges['FIXED_ASSET_R'],
              }
            : {},
          {
            label: i18n.t('AddressBook'),
            description: 'Pravna lica opis.',
            to: '/assets/legal-entities',
            hasAccess: userPrivileges['FIXED_ASSET_R'],
          },
          {
            label: i18n.t('Accounts'),
            hasAccess: userPrivileges['ACCOUNT_R'],
            description: 'Opis.',
            to: '/assets/accounts',
          },
          {
            label: i18n.t('UnitsOfMeasure'),
            hasAccess: userPrivileges['UNIT_OF_MEASURE_R'],
            description: 'Merne jedinice',
            to: '/assets/units-of-measure',
          },
        ].filter((page) => page.hasAccess),
      },
      {
        label: i18n.t('AssetList'),
        description: 'Opis.',
        to: '/assets/asset-lists',
        module: SubSystem.ASSETS,
        hasAccess: userPrivileges['FIXED_ASSET_R'],
        icon: TabletAndroid,
      },
      {
        label: i18n.t('HomePage'),
        description: 'Početna strana opis.',
        to: '/wms',
        module: SubSystem.WMS,
        hasAccess: true,
        icon: Dashboard,
      },
      {
        label: i18n.t('Documents'),
        hasAccess: userPrivileges['FIXED_ASSET_DOCUMENT_R'],
        // to: '/wms/documents',
        icon: DescriptionOutlined,
        module: SubSystem.WMS,
        content: [
          {
            label: i18n.t('InboundDocuments'),
            hasAccess: true,
            description: '',
            content: wmsDocumentTypes
              .filter((type: IWmsDocumentType) => type.category === 'INBOUND')
              .map((type: IWmsDocumentType) => ({
                label: type.name,
                hasAccess: true,
                to: `/wms/documents/${type.uuid}`,
              })),
          },
          {
            label: i18n.t('OutboundDocuments'),
            hasAccess: true,
            description: '',
            content: wmsDocumentTypes
              .filter((type: IWmsDocumentType) => type.category === 'OUTBOUND')
              .map((type: IWmsDocumentType) => ({
                label: type.name,
                hasAccess: true,
                to: `/wms/documents/${type.uuid}`,
              })),
          },
        ],
      },
      {
        label: i18n.t('ItemClassification'),
        description: 'Klasifikacija strana opis.',
        to: '/wms/classifications',
        module: SubSystem.WMS,
        hasAccess: userPrivileges['CLASSIFICATION_R'],
        icon: AccountTree,
      },
      {
        label: i18n.t('MainProducts'),
        description: 'Primarni artikli strana opis.',
        to: '/wms/main-products',
        module: SubSystem.WMS,
        hasAccess: userPrivileges['MAIN_PRODUCT_R'],
        icon: Layers,
      },
      {
        label: i18n.t('Products'),
        description: 'Artikli strana opis.',
        to: '/wms/products',
        module: SubSystem.WMS,
        hasAccess: userPrivileges['PRODUCT_R'],
        icon: LayersOutlined,
      },

      {
        label: i18n.t('Supplies'),
        description: '',
        to: '/wms/supplies',
        module: SubSystem.WMS,
        hasAccess: userPrivileges['PRODUCT_R'],
        icon: HorizontalSplit,
      },
      {
        label: i18n.t('Warehouses'),
        description: 'Skladista strana opis.',
        to: '/wms/warehouses',
        module: SubSystem.WMS,
        hasAccess: userPrivileges['WAREHOUSE_R'],
        icon: Apartment,
      },
      {
        label: i18n.t('Codebooks'),
        hasAccess: userPrivileges['UNIT_OF_MEASURE_R'],
        icon: MenuBook,
        module: SubSystem.WMS,
        content: [
          {
            label: i18n.t('UnitsOfMeasure'),
            hasAccess: userPrivileges['UNIT_OF_MEASURE_R'],
            description: 'Merne jedinice',
            to: '/wms/units-of-measure',
          },
          {
            label: i18n.t('ClassificationTypes'),
            hasAccess: userPrivileges['UNIT_OF_MEASURE_R'],
            description: 'Tipovi klasifikacija',
            to: '/wms/classification-types',
          },
          {
            label: i18n.t('Manufacturers'),
            hasAccess: userPrivileges['WAREHOUSE_R'],
            description: 'Manufacturers.',
            to: '/wms/manufacturers',
          },
          {
            label: i18n.t('CostCenter'),
            hasAccess: userPrivileges['COST_CENTER_W'],
            description: 'CostCenter.',
            to: '/administration/cost-center',
          },
        ].filter((page) => page.hasAccess),
      },
      {
        label: i18n.t('Contracts'),
        to: '/contracts',
        module: SubSystem.CONTRACTS,
        hasAccess: userPrivileges['CONTRACT_R'],
        icon: Gavel,
      },
      {
        label: i18n.t('BillsOfExchange'),
        to: '/contracts/warranties',
        module: SubSystem.CONTRACTS,
        hasAccess: userPrivileges['WARRANTY_R'],
        icon: Business,
      },
      {
        label: i18n.t('BankGuarantee'),
        to: '/contracts/bank-guarantee',
        module: SubSystem.CONTRACTS,
        hasAccess: userPrivileges['WARRANTY_R'],
        icon: Business,
      },
      {
        label: i18n.t('Codebooks'),
        hasAccess: userPrivileges['CONTRACT_R'],
        icon: MenuBook,
        module: SubSystem.CONTRACTS,
        content: [
          {
            label: i18n.t('Currencies'),
            hasAccess: userPrivileges['CONTRACT_R'],
            description: 'Opis.',
            to: '/contracts/currencies',
          },
        ].filter((page) => page.hasAccess),
      },

      /** Small inventory */
      {
        label: i18n.t('Products'),
        description: 'Artikli strana opis.',
        to: '/si/products',
        module: SubSystem.SI,
        hasAccess: userPrivileges['PRODUCT_R'],
        icon: LayersOutlined,
      },
      {
        label: i18n.t('Warehouses'),
        description: 'Skladista strana opis.',
        to: '/si/warehouses',
        module: SubSystem.SI,
        hasAccess: userPrivileges['WAREHOUSE_R'],
        icon: Apartment,
      },
      {
        label: i18n.t('Documents'),
        hasAccess: userPrivileges['WAREHOUSE_R'],
        // to: '/wms/documents',
        icon: DescriptionOutlined,
        module: SubSystem.SI,
        content: wmsDocumentTypes.map((type: IWmsDocumentType) => ({
          label: type.name,
          hasAccess: true,
          to: `/si/documents/${type.uuid}`,
        })),
      },
      {
        label: i18n.t('Supplies'),
        description: '',
        to: '/si/supplies',
        module: SubSystem.SI,
        hasAccess: userPrivileges['PRODUCT_R'],
        icon: HorizontalSplit,
      },
      {
        label: i18n.t('Reports'),
        hasAccess: true,
        icon: Assessment,
        module: SubSystem.SI,
        to: '/si/reports',
      },
      {
        label: i18n.t('AuditLogs'),
        hasAccess: true,
        icon: AccessTimeSharp,
        module: SubSystem.SI,
        to: '/si/audit-logs',
      },
      {
        label: i18n.t('Codebooks'),
        hasAccess: true,
        icon: MenuBook,
        module: SubSystem.SI,
        content: [
          {
            label: i18n.t('DocumentTypes'),
            hasAccess: true,
            to: '/si/document-types',
          },
        ],
      },

      /** Procurement */
      {
        label: i18n.t('HomePage'),
        description: 'Opis.',
        to: '/' + SubSystem.PROCUREMENT,
        module: SubSystem.PROCUREMENT,
        hasAccess: true,
        icon: Dashboard,
      },
      // {
      //   label: i18n.t('AnnualNeeds'),
      //   to: '/procurement/plans/' + ProcurementPlanCategory.ANNUAL_NEEDS,
      //   module: SubSystem.PROCUREMENT,
      //   hasAccess: true,
      //   icon: Bookmark,
      // },
      // {
      //   label: i18n.t('YearlyPlan'),
      //   to: '/procurement/plans/' + ProcurementPlanCategory.YEARLY_PLAN,
      //   module: SubSystem.PROCUREMENT,
      //   hasAccess: true,
      //   icon: Book,
      // },
      {
        label: i18n.t('ProcurementGroups'),
        to: '/procurement/estimate/groups',
        module: SubSystem.PROCUREMENT,
        hasAccess: true,
        icon: Assessment,
      },
      {
        label: i18n.t('ProcurementYearlyPlans'),
        to: '/procurement/yearly-plans',
        module: SubSystem.PROCUREMENT,
        hasAccess: true,
        icon: Assessment,
      },
      {
        label: i18n.t('Codebooks'),
        hasAccess: userPrivileges['USER_R'],
        icon: MenuBook,
        module: SubSystem.PROCUREMENT,
        content: [
          {
            label: i18n.t('Accounts'),
            hasAccess: userPrivileges['CODEBOOK_W'],
            description: 'Opis.',
            to: '/procurement/accounts',
          },
          {
            label: i18n.t('ProcurementPlanPart'),
            hasAccess: userPrivileges['CODEBOOK_W'],
            description: 'Opis.',
            to: '/procurement/plan/parts',
          },
        ],
      },
    ].filter((page) => page.hasAccess),
  },
];

export default navItems;
