import { FC, Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, Button, List, ListItem, Divider } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getActiveOrganization } from '../../utils/LocalStorageHelper';
import { ChangeOrganizationDialog } from '../Dialogs/ChangeOrganizationDialog';
import { AccountBalanceTwoTone, ExitToAppTwoTone } from '@material-ui/icons';
import { UserAvatar } from '../Avatar/UserAvatar';
import { logOut } from '../../services/AuthServices';
import { maxChar } from '../../utils/StringUtils';
import { IReduxState } from '../../entity/IRedux';

export const HeaderUserBox: FC = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isOrgDialogOpen, setIsOrgDialogOpen] = useState(false);

  const { t } = useTranslation();
  const user = useSelector((state: IReduxState) => state.activeUserStore.userInfo);
  const organization = getActiveOrganization();

  return (
    <Fragment>
      <Button
        color="inherit"
        onClick={(event: any) => setAnchorEl(event.currentTarget)}
        className="text-capitalize px-3 text-left btn-inverse d-flex align-items-center">
        <UserAvatar user={user} />
        {user && organization && (
          <div className="header-user-details pl-3">
            <div className="font-weight-bold pt-2 line-height-1">{`${user.firstName} ${user.lastName}`}</div>
            <span className="text-white-50">{maxChar(organization.name)}</span>
          </div>
        )}
        <span className="pl-1 pl-xl-3">
          <FontAwesomeIcon icon={['fas', 'angle-down']} className="opacity-5" />
        </span>
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        onClose={() => setAnchorEl(null)}
        className="ml-2">
        <div className="dropdown-menu-right dropdown-menu-lg overflow-hidden p-0">
          <List className="text-left bg-transparent d-flex align-items-center flex-column pt-0">
            <UserAvatar user={user} size={50} />
            {user && organization && (
              <div className="text-center">
                <div className="font-weight-bold pt-2 line-height-1">
                  {`${user.firstName} ${user.lastName}`}
                </div>
                <span className="text-black-50">{organization.name}</span>
              </div>
            )}
            <Divider className="w-100 mt-2" />
            <ListItem onClick={() => setIsOrgDialogOpen(true)} button>
              <AccountBalanceTwoTone className="mr-1" />
              {t('ChangeOrganization')}
            </ListItem>
            <ListItem onClick={logOut} button>
              <ExitToAppTwoTone className="mr-1" />
              {t('LogOut')}
            </ListItem>
          </List>
        </div>
      </Menu>
      <ChangeOrganizationDialog
        isOpen={isOrgDialogOpen}
        handleClosePopover={() => setIsOrgDialogOpen(false)}
      />
    </Fragment>
  );
};
