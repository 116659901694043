export const emptyOrValue = (value: any) => (value ? value : '');

export const localizedStringOrDefault = (
  translate: any,
  message: string,
  defualt: string
) => {
  const rez = translate(message);
  if (rez === message) {
    return defualt;
  }
  return rez;
};
export const valueOrEmpty = (value: string = '') => value;
export const maxChar = (str: string = '', maxLength: number = 30) =>
  str.length > maxLength ? `${str.substring(0, maxLength)}...` : str;
