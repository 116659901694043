const initState = {
  notes: [],
  totalRows: 0,
  loading: false,
  note: null,
  noteUuid: '',
  editForm: false,
  submitFailMode: false,
};

export enum NotesReducerActions {
  FETCH_NOTES = 'FETCH_NOTES',
  FETCH_NOTES_SUCCESSFUL = 'FETCH_NOTES_SUCCESSFUL',
  FETCH_NOTES_FAIL = 'FETCH_NOTES_FAIL',
  CHANGE_NOTE_MODE = 'CHANGE_NOTE_MODE',
  FETCH_NOTE = 'FETCH_NOTE',
  FETCH_NOTE_FAIL = 'FETCH_NOTE_FAIL',
  EDIT_SAVE_NOTE = 'EDIT_SAVE_NOTE',
  EDIT_SAVE_NOTE_SUCCESSFUL = 'EDIT_SAVE_NOTE_SUCCESSFUL',
  EDIT_SAVE_NOTE_FAIL = 'EDIT_SAVE_NOTE_FAIL',
}

const NotesReducer = (state = initState, action: any) => {
  switch (action.type) {
    case NotesReducerActions.FETCH_NOTES:
      return { ...state, loading: true };
    case NotesReducerActions.FETCH_NOTES_SUCCESSFUL:
      return {
        ...state,
        notes: action.payload.notes,
        totalRows: action.payload.totalRows,
        loading: false,
      };
    case NotesReducerActions.FETCH_NOTES_FAIL:
      return { ...state, notes: [], totalRows: 0, loading: false };
    case NotesReducerActions.CHANGE_NOTE_MODE:
      return {
        ...state,
        editForm: action.payload,
        submitFailMode: false,
        note: null,
      };
    case NotesReducerActions.FETCH_NOTE:
      return {
        ...state,
        note: action.payload,
        editForm: true,
        submitFailMode: false,
      };
    case NotesReducerActions.FETCH_NOTE_FAIL:
      return {
        ...state,
        note: null,
        editForm: false,
        noteUuid: '',
        submitFailMode: false,
      };
    case NotesReducerActions.EDIT_SAVE_NOTE:
      return { ...state, submitFailMode: true };
    case NotesReducerActions.EDIT_SAVE_NOTE_SUCCESSFUL:
      return { ...state, note: null, editForm: false, submitFailMode: false };
    case NotesReducerActions.EDIT_SAVE_NOTE_FAIL:
      return { ...state, submitFailMode: true };
    default:
      return state;
  }
};

export default NotesReducer;
