import { Menu } from '@material-ui/core';
import { FC, useState } from 'react';
import { CButton, IButtonType } from '../Buttons/CButton';
import { CBadge } from '../Buttons/CBadge';
import { ITableSort } from '../../entity/ITable';
import { SetSortList } from './SetSortList';

interface Props {
  setTableCols?: (data: any) => void;
  filterData?: {
    activeFilterNo?: number;
    createElement: (onClose: () => void) => any;
    setFilter: (filter: any) => void;
  };
  sortData?: {
    sort: string;
    setSort: (sort: string) => void;
    sortOptions: ITableSort;
  };
  allowOverflow?: boolean;
  customButton?: {
    type: IButtonType;
    size: 'xs' | 's' | 'm' | 'l';
    onClick?: () => void;
    title?: string;
  };
}
export const TableOptions: FC<Props> = ({
  setTableCols,
  filterData,
  sortData,
  allowOverflow,
  customButton,
}) => {
  const [anchorElMenu, setAnchorElMenu] = useState<Element | null>(null);
  const [openedMenu, setOpenedMenu] = useState<'filter' | 'sort' | 'columns' | ''>('');

  const onClose = () => {
    setAnchorElMenu(null);
    setOpenedMenu('');
  };

  return (
    <div className="table-options">
      {filterData && (
        <CBadge number={filterData.activeFilterNo} color="primary" className="mr-2">
          <CButton
            type="Filter"
            size="s"
            className="mr-1"
            isResponsive
            active={openedMenu === 'filter'}
            onClick={(event: any) => {
              setAnchorElMenu(event.currentTarget);
              setOpenedMenu('filter');
            }}
          />
        </CBadge>
      )}
      {sortData && (
        <CButton
          type="Sort"
          size="s"
          className="mr-1"
          isResponsive
          active={openedMenu === 'sort'}
          onClick={(event: any) => {
            setAnchorElMenu(event.currentTarget);
            setOpenedMenu('sort');
          }}
        />
      )}
      {!!setTableCols && (
        <CButton
          type="Columns"
          size="s"
          className="mr-1"
          isResponsive
          active={openedMenu === 'columns'}
          onClick={(event: any) => {
            setAnchorElMenu(event.currentTarget);
            setOpenedMenu('columns');
          }}
        />
      )}
      {!!customButton && (
        <CButton
          title={customButton.title}
          type={customButton.type}
          size={customButton.size}
          className="mr-1"
          isResponsive
          onClick={customButton.onClick}
        />
      )}
      {!!openedMenu && (
        <Menu
          anchorEl={anchorElMenu}
          open={!!anchorElMenu}
          onClose={onClose}
          classes={{
            list: 'p-0',
            paper: allowOverflow ? 'overflow-visible menu-base' : 'menu-base',
          }}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
          <div>
            {openedMenu === 'filter' && filterData?.createElement(onClose)}
            {openedMenu === 'sort' && (
              <SetSortList
                sort={sortData!.sort}
                setSort={sortData!.setSort}
                sortOptions={sortData!.sortOptions}
              />
            )}
          </div>
        </Menu>
      )}
    </div>
  );
};
