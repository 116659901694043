import { LinearProgress } from '@material-ui/core';

export const LazyLoader = () => (
  <div className="d-flex align-items-center vh-100 justify-content-center text-center font-size-lg">
    <div className="w-50">
      Loading...
      <LinearProgress className="linear-progress" />
    </div>
  </div>
);
